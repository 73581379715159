import { Grid, SxProps, Typography } from '@mui/material'
import { RequiredMarker } from '..'
interface FormRowGenericProps {
  label: string
  value?: string | undefined
  useSemicolon?: boolean
  required?: boolean
  children?: JSX.Element | JSX.Element[]
  sx?: SxProps
}

export const FormRowGeneric = ({
  label,
  value,
  useSemicolon,
  required,
  children,
  sx,
}: FormRowGenericProps) => {
  return (
    <Grid
      container
      sx={[
        ...(Array.isArray(sx) ? sx : [sx]),
        {
          alignItems: 'center',
        },
      ]}
    >
      <Grid item xs={6}>
        <Typography variant="body1" sx={{ pr: 2, pb: '3px' }}>
          {label}
          {useSemicolon ? ':' : undefined}
          {required && <RequiredMarker />}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        {children ? children : <Typography>{value}</Typography>}
      </Grid>
    </Grid>
  )
}
