import { useNavigate, useRoutes } from 'react-router-dom'
import { routesConfig } from './routesConfig'
import { ThemeProvider } from './providers'
import { initInterceptors } from './api'
import { QueryClient, QueryClientProvider } from 'react-query'
import { FullPageLoader, Snackbar } from './components'
import { Suspense } from 'react'

const queryClient = new QueryClient()

export const App = () => {
  const routes = useRoutes(routesConfig)
  const navigate = useNavigate()

  initInterceptors(navigate)

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider>
        <Suspense fallback={<FullPageLoader />}>{routes}</Suspense>
      </ThemeProvider>
      <Snackbar />
    </QueryClientProvider>
  )
}
