import { Header, PageWrap } from '../../components'
import { Init } from '../Init'

export const Layout = () => {
  return (
    <>
      <Header />
      <PageWrap>
        <Init />
      </PageWrap>
    </>
  )
}
