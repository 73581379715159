import { Box, Button, Divider } from '@mui/material'
import { ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'
import {
  AddressAutocompleteRow,
  FileControl,
  FormRowGeneric,
  FormTextRow,
  OutlinedContainer,
} from '../../../components'
import { ACCEPTED_IMAGE_FILE_TYPES } from '../../../constants'
import { PlaceAddress, PartnerPlace, FileListItem } from '../../../interfaces'

interface PartnerRegisterPlaceProps {
  partnerPlaces: PartnerPlace[]
  onTextFieldChange: (event: ChangeEvent<HTMLInputElement>, index: number) => void
  onPlaceAddressChange: (address: PlaceAddress, index: number) => void
  addPlace: () => void
  removePlace: (index: number) => void
  onPlacePhotoChange: (placePhoto: FileListItem[], index: number) => void
}

export const PartnerRegisterPlace = ({
  partnerPlaces,
  onTextFieldChange,
  onPlaceAddressChange,
  addPlace,
  removePlace,
  onPlacePhotoChange,
}: PartnerRegisterPlaceProps) => {
  const { t } = useTranslation()

  return (
    <OutlinedContainer label={t('places.place')}>
      <>
        {partnerPlaces.map((place, index) => (
          <Box
            key={index}
            sx={{
              '& > div:not(:first-of-type)': {
                mt: 2,
              },
            }}
          >
            <AddressAutocompleteRow
              value={place.adrese}
              label={t('places.address')}
              onChange={(address) => onPlaceAddressChange(address, index)}
            />
            <FormTextRow
              label={t('comment')}
              value={place.komentārs}
              name="komentārs"
              multiline={true}
              rows={4}
              onChange={(event) => onTextFieldChange(event, index)}
            />
            <FormRowGeneric label={t('image')}>
              <FileControl
                files={place.placePhoto || []}
                multiple={false}
                acceptedUploadFileTypes={ACCEPTED_IMAGE_FILE_TYPES}
                onFileChange={(files) => onPlacePhotoChange(files, index)}
              />
            </FormRowGeneric>
            <Box sx={{ mt: 2, textAlign: 'right' }}>
              <Button onClick={() => removePlace(index)} variant="outlined">
                {t('places.remove')}
              </Button>
            </Box>
            <Divider sx={{ mt: 2 }} />
          </Box>
        ))}
      </>
      <Box sx={{ mt: 2, textAlign: 'right' }}>
        <Button onClick={addPlace} variant="contained">
          {t('places.add')}
        </Button>
      </Box>
    </OutlinedContainer>
  )
}
