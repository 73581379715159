import { Header, PageWrap } from '../../components'

interface BasicLayoutProps {
  children: JSX.Element
}

export const BasicLayout = ({ children }: BasicLayoutProps) => {
  return (
    <>
      <Header />
      <PageWrap>{children}</PageWrap>
    </>
  )
}
