import { AppBar, IconButton, Toolbar, Typography, Button, Box } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { LogoIcon } from '../LogoIcon'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import { useCurrentUserStore } from '../../store'
import { PartnerSelect } from '../PartnerSelect'
import { LanguageSelect } from '../LanguageSelect'
import { useTranslation } from 'react-i18next'

export const Header = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const currentUser = useCurrentUserStore((state) => state.currentUser)

  const goHome = () => {
    navigate('/')
  }

  const logout = () => {
    navigate('/logout', { replace: true })
  }

  const registerNewPartner = () => {
    navigate('/register')
  }

  return (
    <AppBar position="static">
      <Toolbar>
        <IconButton onClick={goHome}>
          <LogoIcon style={{ width: 55, height: 55, fill: '#FFF' }} />
        </IconButton>
        <Typography sx={{ lineHeight: '1.3', width: '8rem', textAlign: 'center', fontWeight: 500 }}>
          Latvijas Goda Ģimene
        </Typography>
        <Box sx={{ ml: 4, width: '100%', display: 'flex', alignItems: 'center' }}>
          {currentUser && (
            <>
              <PartnerSelect />
              <Button onClick={registerNewPartner} variant="outlined" sx={{ ml: 1, color: '#FFF' }}>
                {t('register-new')}
              </Button>
            </>
          )}

          <Box sx={{ ml: 'auto' }}>
            <LanguageSelect />
            {currentUser && (
              <Button onClick={logout} sx={{ ml: 4, color: '#FFF' }} endIcon={<ExitToAppIcon />}>
                {t('logout')}
              </Button>
            )}
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  )
}
