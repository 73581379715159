import axios from 'axios'
import { ClassifierCode } from '../../enums'
import queryString from 'query-string'
import { ClassifierResponse, SelectItem } from '../../interfaces'

interface ClassifierRequest {
  klasifikatora_kods: ClassifierCode
}

export const fetchClassifier = (classifierRequest: ClassifierRequest) => {
  const queryParams = queryString.stringify(classifierRequest, {
    arrayFormat: 'none',
    skipNull: true,
  })

  return axios
    .get<ClassifierResponse[]>('/data/klasifikatora_vērtību_saraksts?' + queryParams)
    .then((res) => {
      return res.data.map((classifier) => ({
        name: classifier.nosaukums,
        code: classifier.kods,
      })) as SelectItem[]
    })
}
