import { Box, TextField, Typography, Button } from '@mui/material'
import { FormEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { loginUser } from '../../api'
import { getConfig } from '../../api/config/config'
import { VpsButton } from '../../components'
import { INVALID_PERSONAL_NUMBER, REGEX_PERSON_CODE } from '../../constants'
import { useCurrentUserStore, useSnackbarStore } from '../../store'
import { parseError } from '../../utils'

export const Login = () => {
  const showSnackbar = useSnackbarStore((state) => state.showSnackbar)
  const { currentUser, setCurrentUser } = useCurrentUserStore()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [personalIdNumber, setPersonalIdNumber] = useState('')
  const [invalidPersonalIdNumber, setInvalidPersonalIdNumber] = useState(false)
  const { data: configData } = useQuery('config-data', getConfig, {
    refetchOnWindowFocus: false,
  })
  const { mutateAsync: login, isLoading } = useMutation((pk: string) => loginUser(pk), {
    onError: (error) => {
      showSnackbar(parseError(error, t), { severity: 'error' })
    },
  })

  useEffect(() => {
    // proactively logs user out if he/she lands on login with active session
    if (currentUser) {
      navigate('/logout', { replace: true })
    }
  }, [currentUser, navigate])

  const onSubmit = (event: FormEvent) => {
    event.preventDefault()

    if (!personalIdNumber.match(REGEX_PERSON_CODE)) {
      setInvalidPersonalIdNumber(true)
      return
    }

    login(personalIdNumber)
      .then((currentUser) => setCurrentUser(currentUser))
      .then(() => navigate('/', { replace: true }))
  }

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPersonalIdNumber(event.target.value)
    invalidPersonalIdNumber && setInvalidPersonalIdNumber(false)
  }

  return (
    <Box sx={{ m: 'auto' }}>
      <Typography variant="h1" color="primary" textAlign="center">
        {t('portal-name')}
      </Typography>
      <Box sx={{ mr: 'auto', ml: 'auto', maxWidth: '20rem', textAlign: 'center' }}>
        {configData?.test ? (
          <Box component="form" onSubmit={onSubmit} sx={{ mt: 4 }}>
            <Typography variant="h2">{t('test-auth')}</Typography>
            <Box>
              <TextField
                value={personalIdNumber}
                onChange={onChange}
                autoComplete="off"
                sx={{ mt: 1, width: '100%' }}
                label={t('users.personal-number')}
                helperText={invalidPersonalIdNumber ? t(INVALID_PERSONAL_NUMBER) : undefined}
                error={invalidPersonalIdNumber}
              />
            </Box>
            <Button
              sx={{ mt: 2, minHeight: '50px', width: '100%' }}
              type="submit"
              disabled={isLoading}
              variant="outlined"
            >
              {t('login')}
            </Button>
            <Typography variant="caption" sx={{ mt: 2, display: 'block' }}>
              {t('version')}: {process.env.REACT_APP_VERSION}
            </Typography>
          </Box>
        ) : (
          <>
            <Typography sx={{ mt: 2 }}>{t('auth-with')}</Typography>
            <VpsButton sx={{ mt: 1, width: '100%', minHeight: '50px' }} />
          </>
        )}
      </Box>
    </Box>
  )
}
