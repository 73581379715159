import create from 'zustand'
import { SnackbarConfig } from '../interfaces'

const defaultConfiguration: SnackbarConfig = {
  vertical: 'top',
  horizontal: 'center',
  autoHideDuration: 10000,
  severity: 'success',
}

interface SnackbarStore {
  open: boolean
  message: string
  configuration: SnackbarConfig
  showSnackbar: (msg: string, config?: Partial<SnackbarConfig>) => void
  closeSnackbar: () => void
}

export const useSnackbarStore = create<SnackbarStore>((set) => ({
  open: false,
  message: '',
  configuration: defaultConfiguration,
  showSnackbar: (msg: string, config?: Partial<SnackbarConfig>) => {
    set(() => ({
      configuration: {
        ...defaultConfiguration,
        ...config,
      },
      message: msg,
      open: true,
    }))
  },
  closeSnackbar: () => {
    set(() => ({
      open: false,
    }))
  },
}))
