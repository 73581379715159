import { useEffect } from 'react'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { logoutUser } from '../../api'
import { FullPageLoader } from '../../components'
import { useCurrentUserStore, usePartnerStore } from '../../store'

export const Logout = () => {
  const navigate = useNavigate()
  const { resetUserStore } = useCurrentUserStore()
  const { setCurrentPartner, setPartnerList } = usePartnerStore()

  const { refetch: logout } = useQuery('logout', logoutUser, {
    refetchOnWindowFocus: false,
    enabled: false,
  })

  useEffect(() => {
    setCurrentPartner(undefined)
    setPartnerList(undefined)
    resetUserStore()

    localStorage.clear()

    logout().then(() => navigate('/login', { replace: true }))
  }, [logout, navigate, setCurrentPartner, setPartnerList, resetUserStore])

  return <FullPageLoader />
}
