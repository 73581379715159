import { ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { FormTextRow, OutlinedContainer } from '..'
import { PartnerNew } from '../../interfaces'

interface PartnerEditDetailsProps {
  partnerData: PartnerNew
  onTextFieldChange: (event: ChangeEvent<HTMLInputElement>) => void
  onAddressChange: (event: ChangeEvent<HTMLInputElement>) => void
}

export const PartnerEditDetails = ({
  partnerData,
  onTextFieldChange,
  onAddressChange,
}: PartnerEditDetailsProps) => {
  const { t } = useTranslation()

  return (
    <OutlinedContainer label={t('partner.details-info')}>
      <FormTextRow
        label={t('partner.vat-number')}
        value={partnerData.pvn_reģistrācijas_numurs}
        name="pvn_reģistrācijas_numurs"
        onChange={onTextFieldChange}
      />
      <FormTextRow
        label={t('partner.legal-address')}
        value={partnerData.juridiskā_adrese?.adrese}
        name="juridiskā_adrese"
        onChange={onAddressChange}
      />
      <FormTextRow
        label={t('partner.bank')}
        value={partnerData.banka}
        name="banka"
        onChange={onTextFieldChange}
      />
      <FormTextRow
        label={t('partner.bank-account')}
        value={partnerData.konta_numurs}
        name="konta_numurs"
        onChange={onTextFieldChange}
      />
      <FormTextRow
        label={t('partner.swift')}
        value={partnerData.swift}
        name="swift"
        onChange={onTextFieldChange}
      />
    </OutlinedContainer>
  )
}
