import React from 'react'
import { Navigate } from 'react-router-dom'
import { PartnerRoleStatus } from '../../enums'
import { useCurrentUserStore } from '../../store'

export const RoleGuard: React.FC<{ children: JSX.Element; allowedRoles: PartnerRoleStatus[] }> = ({
  children,
  allowedRoles,
}) => {
  const { currentUserRole } = useCurrentUserStore()

  if (allowedRoles.includes(currentUserRole as PartnerRoleStatus)) {
    return children
  }

  if (currentUserRole === PartnerRoleStatus.KAS) {
    return <Navigate to="/certificate-verificaiton" replace />
  }

  return <Navigate to="/" replace />
}
