import { useState } from 'react'
import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import BlockIcon from '@mui/icons-material/Block'
import { NavBar, OutlinedContainer } from '../../components'
import { useQuery } from 'react-query'
import { certificateVerification } from '../../api/certificateVerification'
import {
  CertificateStatus,
  CertificateVerificationResponse,
} from '../../interfaces/certificateVerification'
import { AxiosError } from 'axios'
import { usePartnerStore } from '../../store'
import { PartnerStatusCode } from '../../interfaces/partner/PartnerStatusCode'

export const CertificateVerification: React.FC = () => {
  const { t } = useTranslation()
  const theme = useTheme()
  const { currentPartnerStatus } = usePartnerStore()
  const isCurrentPartnerActive = currentPartnerStatus === PartnerStatusCode.Aktīvs
  const [certificateNumber, setCertificateNumber] = useState<string>('')
  const [certificationStatus, setCertificationStatus] = useState<CertificateStatus | undefined>(
    undefined
  )
  const [incorrectCertificateNumber, setIncorrectCertificationNumber] = useState<string>('')

  const { refetch: verifyCertificate, isFetching } = useQuery(
    'certificate-verification',
    () => certificateVerification(certificateNumber),
    {
      onSuccess: (data: CertificateVerificationResponse) => {
        setCertificationStatus(data.statuss)
      },
      onError: (error: AxiosError) => {
        setCertificationStatus(undefined)
        if (error.response?.status === 404) {
          setIncorrectCertificationNumber('Nepareizs apliecības numurs')
        }
      },
      refetchOnWindowFocus: false,
      enabled: false,
      retry: false,
    }
  )

  const handleCertificateNumber = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (!e.target.value) {
      setCertificationStatus(undefined)
    }

    setCertificateNumber(e.target.value)
    setIncorrectCertificationNumber('')
  }

  const handleVerifyCertificate = () => {
    if (certificateNumber) {
      verifyCertificate()
    }
  }

  return (
    <Box width="100%">
      <NavBar />

      <OutlinedContainer
        wrapperStyle={{
          height: '40%',
          ...(certificationStatus && {
            backgroundColor:
              certificationStatus === CertificateStatus.DERĪGA ? '#ebfbe5' : '#FFCCCB',
          }),
        }}
        innerWrapperStyle={{ height: '100%', paddingTop: 0 }}
        label={t('certificate-verification.title')}
      >
        {isCurrentPartnerActive ? (
          <Box display="flex" justifyContent="center" alignContent="center" height="100%">
            <Box display="flex" justifyContent="center" flexDirection="column">
              <Box
                display="flex"
                sx={{
                  [theme.breakpoints.down(480)]: {
                    flexDirection: 'column',
                  },
                }}
              >
                <Typography style={{ marginRight: '40px', marginTop: '8px' }}>
                  {t('certificate-verification.certificate-number')}
                </Typography>
                <Box width="300px" display="flex" justifyContent="center" flexDirection="column">
                  <TextField
                    size="small"
                    value={certificateNumber}
                    onChange={handleCertificateNumber}
                    error={Boolean(incorrectCertificateNumber)}
                    {...(certificationStatus && {
                      sx: {
                        fieldset: {
                          border: `2px solid ${
                            certificationStatus === CertificateStatus.DERĪGA
                              ? theme.palette.success.main
                              : theme.palette.error.main
                          }`,
                        },
                      },
                      InputProps: {
                        endAdornment:
                          certificationStatus === CertificateStatus.DERĪGA ? (
                            <Tooltip
                              title={t('certificate-verification.certificate-valid')}
                              placement="top"
                            >
                              <CheckCircleIcon color="success" />
                            </Tooltip>
                          ) : (
                            <Tooltip
                              title={t('certificate-verification.certificate-invalid')}
                              placement="top"
                            >
                              <BlockIcon color="error" />
                            </Tooltip>
                          ),
                      },
                    })}
                  />
                  <Box marginTop="4px">
                    {incorrectCertificateNumber ? (
                      <Typography color="error" fontSize="12px">
                        {incorrectCertificateNumber}
                      </Typography>
                    ) : (
                      <Typography textAlign="center" fontSize="12px">
                        {t('certificate-verification.input-helper-message')}
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Box>
              <Box display="flex" justifyContent="center">
                <Button
                  variant="contained"
                  style={{ width: '200px', marginTop: '20px' }}
                  onClick={handleVerifyCertificate}
                  disabled={isFetching}
                >
                  {isFetching ? (
                    <CircularProgress color="inherit" size={24} />
                  ) : (
                    t('certificate-verification.verify')
                  )}
                </Button>
              </Box>
            </Box>
          </Box>
        ) : (
          <Box display="flex" alignItems="center" justifyContent="center" height="100%">
            <Typography fontSize="18px" textAlign="center">
              {t('certificate-verification.unavailable')}
            </Typography>
          </Box>
        )}
      </OutlinedContainer>
    </Box>
  )
}
