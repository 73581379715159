import { SelectChangeEvent, Box, Button, Typography } from '@mui/material'
import { ChangeEvent, FormEvent, useEffect, useState, useRef } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { aggregatedRegisterPartner, fetchClassifier, uploadFiles } from '../../api'
import { ClassifierCode, StorageKey } from '../../enums'
import {
  PlaceAddress,
  PartnerContactPerson,
  PartnerServiceGroup,
  PartnerDiscount,
  PartnerPlace,
  FileListItem,
  EditorRef,
  PartnerTag,
  AggregatedPartnerPlaceRequest,
  AggregatedPartnerNew,
  AggregatedPartnerNewRequest,
  AggregatedPartnerNewResponse,
  FileEntry,
} from '../../interfaces'
import {
  FullPageLoader,
  Grid2xN,
  PartnerEditBasicInformation,
  PartnerEditDetails,
  PartnerEditAdditionalInformation,
  NavBar,
} from '../../components'
import {
  GOODS_SERVICES_LIMIT,
  IMPORTANT_INFO_CHAR_LIMIT,
  INVALID_EMAIL_FORMAT,
  INVALID_HOME_PAGE_URL,
  INVALID_PERSONAL_NUMBER,
  NEW_CONTACT_PERSON,
  NEW_DISCOUNT,
  NEW_ONLINE_STORE,
  NEW_PARTNER_PLACE,
  REGEX_EMAIL_FORMAT,
  REGEX_HOME_PAGE_FORMAT,
  REGEX_PERSON_CODE,
  REQUIRED_ERROR_MESSAGE,
  TAG_LIMIT,
  TOO_LONG_ERROR_MESSAGE,
} from '../../constants'
import { useSnackbarStore } from '../../store'
import { PartnerRegisterContactPersons } from './PartnerRegisterContactPersons'
import { PartnerRegisterDiscount } from './PartnerRegisterDiscount'
import { PartnerRegisterPlace } from './PartnerRegisterPlace'
import { parseError } from '../../utils'
import { useTranslation } from 'react-i18next'
import { getRegistryData } from '../../api/partner/getRegistryData'
import { PartnerFile } from '../../interfaces/partner/PartnerAgreementFile copy'

export const PartnerRegister = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const showSnackbar = useSnackbarStore((state) => state.showSnackbar)

  const editorRef = useRef<EditorRef>(null)

  const [registrationData, setRegistrationData] = useState<AggregatedPartnerNew>({
    reģistrācijas_numurs: '',
    nosaukums: '',
    juridiskais_nosaukums: '',
    veids_kods: '',
    epasts: '',
    pvn_reģistrācijas_numurs: '',
    banka: '',
    konta_numurs: '',
    swift: '',
    mājas_lapa: '',
    pakalpojumu_grupa: [],
    pakalpojumu_birkas: [],
    interneta_veikals: [],
    juridiskā_adrese: {
      koord_x: 0,
      zip: '',
      novads_nosaukums: '',
      pagasts_nosaukums: '',
      koord_y: 0,
      pilseta_atvk: '',
      code: 0,
      novads_atvk: '',
      adrese: '',
      pilseta_nosaukums: '',
      pagasts_atvk: '',
      vzd_adrese: '',
    },
    vieta: [],
    atlaide: [],
    persona: [],
  })

  const [contactPersons, setContactPersons] = useState<PartnerContactPerson[]>([NEW_CONTACT_PERSON])
  const [discounts, setDiscounts] = useState<PartnerDiscount[]>([])
  const [partnerPlaces, setPartnerPlaces] = useState<PartnerPlace[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [isInitializing, setIsInitializing] = useState(true)

  const [formErrors, setFormErrors] = useState<{ [key: string]: string }>({})

  const [publicityPhoto, setPublicityPhoto] = useState<FileListItem[]>([])

  const [logoImage, setLogoImage] = useState<FileListItem[]>([])

  const [uploadedPublicityPhoto, setUploadedPublicityPhoto] = useState<PartnerFile[]>([])

  const [uploadedLogoImage, setUploadedLogoImage] = useState<PartnerFile[]>([])

  const errorHandler = (error: unknown) => {
    setIsLoading(false)
    showSnackbar(parseError(error, t), { severity: 'error' })
  }

  const { data: partnerTypeClassifier, isLoading: loadingPartnerTypeClassifier } = useQuery(
    'partner-type-classifier',
    () => fetchClassifier({ klasifikatora_kods: ClassifierCode.PARTNERA_VEIDS }),
    {
      refetchOnWindowFocus: false,
      onError: errorHandler,
    }
  )

  const { data: serviceGroupClassifier, isLoading: loadingServiceGroupClassifier } = useQuery(
    'service-group-classifier',
    () => fetchClassifier({ klasifikatora_kods: ClassifierCode.PAKALPOJUMU_GRUPA }),
    {
      refetchOnWindowFocus: false,
      onError: errorHandler,
    }
  )

  const {
    data: partnerContactPersonRoleClassifier,
    isLoading: loadingPartnerContactPersonRoleClassifier,
  } = useQuery(
    'partner-contact-person-role-classifier',
    () => fetchClassifier({ klasifikatora_kods: ClassifierCode.PARTNERA_PERSONAS_LOMA }),
    {
      refetchOnWindowFocus: false,
      onError: errorHandler,
    }
  )

  const { data: partnerDiscountUnitClassifier, isLoading: loadingPartnerDiscountUnitClassifier } =
    useQuery(
      'partner-discount-unit-classifier',
      () => fetchClassifier({ klasifikatora_kods: ClassifierCode.MĒRVIENĪBA }),
      {
        refetchOnWindowFocus: false,
        onError: errorHandler,
      }
    )

  const { data: tagClassifier, isLoading: loadingTagClassifier } = useQuery(
    'tag-classifier',
    () => fetchClassifier({ klasifikatora_kods: ClassifierCode.BIRKA }),
    {
      refetchOnWindowFocus: false,
      onError: errorHandler,
    }
  )

  const { refetch: fetchRegistryData, isFetching: isRegistryDataLoading } = useQuery(
    'registry-data',
    () => getRegistryData(registrationData.reģistrācijas_numurs),
    {
      onSuccess: (data) => {
        const { address, legalForm, name } = data

        setRegistrationData({
          ...registrationData,
          juridiskā_adrese: {
            adrese: address,
          },
          veids_kods: legalForm,
          nosaukums: name,
          juridiskais_nosaukums: name,
        })
      },
      onError: errorHandler,
      retry: false,
      refetchOnWindowFocus: false,
      enabled: false,
    }
  )

  const { mutate: aggregatedPartnerRegisterMutation } = useMutation<
    AggregatedPartnerNewResponse,
    unknown,
    AggregatedPartnerNewRequest
  >((aggregatedRegistrationData) => aggregatedRegisterPartner(aggregatedRegistrationData), {
    onSuccess: (data) => {
      showSnackbar(t('partner.added-new-partner'))
      setIsLoading(false)

      // delay to allow user to see success snackbar
      setTimeout(() => {
        localStorage.setItem(StorageKey.SelectedPartnerId, data.id + '_PIE')
        window.location.replace('/')
      }, 1000)
    },
  })

  useEffect(() => {
    setIsInitializing(
      loadingPartnerTypeClassifier ||
        loadingServiceGroupClassifier ||
        loadingPartnerContactPersonRoleClassifier ||
        loadingPartnerDiscountUnitClassifier ||
        loadingTagClassifier
    )
  }, [
    loadingPartnerContactPersonRoleClassifier,
    loadingPartnerDiscountUnitClassifier,
    loadingPartnerTypeClassifier,
    loadingServiceGroupClassifier,
    loadingTagClassifier,
  ])

  const onRegistrationDataTextFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
    setRegistrationData({
      ...registrationData,
      [event.target.name]: event.target.value,
    })

    setFormErrors({
      ...formErrors,
      [event.target.name]: '',
    })
  }

  const onRegistrationDataSelectChange = (event: SelectChangeEvent<string>) => {
    setRegistrationData({
      ...registrationData,
      [event.target.name]: event.target.value,
    })

    setFormErrors({
      ...formErrors,
      [event.target.name]: '',
    })
  }

  const onAddressChange = (event: ChangeEvent<HTMLInputElement>) => {
    setRegistrationData({
      ...registrationData,
      juridiskā_adrese: {
        adrese: event.target.value,
      },
    })
  }

  const onContactPersonTextFieldChange = (event: ChangeEvent<HTMLInputElement>, index: number) => {
    const copy = contactPersons.slice()

    copy[index] = {
      ...copy[index],
      [event.target.name]: event.target.value,
    }

    setContactPersons(copy)

    setFormErrors({
      ...formErrors,
      [event.target.name + index]: '',
    })
  }

  const onContactPersonSelectChange = (event: SelectChangeEvent<string>, index: number) => {
    const copy = contactPersons.slice()

    copy[index] = {
      ...copy[index],
      [event.target.name]: event.target.value,
    }

    setContactPersons(copy)

    setFormErrors({
      ...formErrors,
      [event.target.name + index]: '',
    })
  }

  const addContactPerson = () => {
    const copy = contactPersons.slice()

    copy.push(NEW_CONTACT_PERSON)

    setContactPersons(copy)
  }

  const removeContactPerson = (index: number) => {
    const copy = contactPersons.slice()

    copy.splice(index, 1)

    setContactPersons(copy)
  }

  const onDiscountTextFieldChange = (event: ChangeEvent<HTMLInputElement>, index: number) => {
    const copy = discounts.slice()

    copy[index] = {
      ...copy[index],
      [event.target.name]: event.target.value,
    }

    // input returns quantity as string, but API expects it as number
    if (event.target.name === 'skaits') {
      if (event.target.value) {
        copy[index].skaits = parseInt(copy[index].skaits as unknown as string, 10)
      } else {
        copy[index].skaits = 0
      }
    }

    setDiscounts(copy)

    setFormErrors({
      ...formErrors,
      [event.target.name + index]: '',
    })
  }

  const onDiscountSelectChange = (event: SelectChangeEvent<string>, index: number) => {
    const copy = discounts.slice()

    copy[index] = {
      ...copy[index],
      [event.target.name]: event.target.value,
    }

    setDiscounts(copy)

    setFormErrors({
      ...formErrors,
      [event.target.name + index]: '',
    })
  }

  const addDiscount = () => {
    const copy = discounts.slice()

    copy.push(NEW_DISCOUNT)

    setDiscounts(copy)
  }

  const removeDiscount = (index: number) => {
    const copy = discounts.slice()

    copy.splice(index, 1)

    setDiscounts(copy)
  }

  const onServiceGroupChange = (event: SelectChangeEvent<string[]>) => {
    let serviceGroups: PartnerServiceGroup[] = []

    if (Array.isArray(event.target.value)) {
      serviceGroups = event.target.value.map((groupCode) => ({ grupa_kods: groupCode }))
    }

    if (serviceGroups.length > GOODS_SERVICES_LIMIT) {
      showSnackbar(t('errors.too-many-goods-services', { limit: GOODS_SERVICES_LIMIT }), {
        severity: 'info',
      })
      return
    }

    setRegistrationData({
      ...registrationData,
      pakalpojumu_grupa: serviceGroups,
    })
  }

  const onTagChange = (event: SelectChangeEvent<string[]>) => {
    let tags: PartnerTag[] = []

    if (Array.isArray(event.target.value)) {
      tags = event.target.value.map((tagCode) => ({ birka_kods: tagCode }))
    }

    if (tags.length > TAG_LIMIT) {
      showSnackbar(t('errors.too-many-tags', { limit: TAG_LIMIT }), { severity: 'info' })
      return
    }

    setRegistrationData({
      ...registrationData,
      pakalpojumu_birkas: tags,
    })
  }

  const onPlacesTextFieldChange = (event: ChangeEvent<HTMLInputElement>, index: number) => {
    const copy = partnerPlaces.slice()

    copy[index] = {
      ...copy[index],
      [event.target.name]: event.target.value,
    }

    setPartnerPlaces(copy)
  }

  const onPlaceAddressChange = (address: PlaceAddress, index: number) => {
    const copy = partnerPlaces.slice()

    copy[index] = {
      ...copy[index],
      adrese: address,
    }

    setPartnerPlaces(copy)
  }

  const addPlace = () => {
    const copy = partnerPlaces.slice()

    copy.push(NEW_PARTNER_PLACE)

    setPartnerPlaces(copy)
  }

  const removePlace = (index: number) => {
    const copy = partnerPlaces.slice()

    copy.splice(index, 1)

    setPartnerPlaces(copy)
  }

  const onPublicityPhotoChange = (photo: FileListItem[]) => {
    setPublicityPhoto(photo)
    setIsLoading(true)

    uploadFiles(photo)
      .then(async (uploadResponse: Response[]) => {
        for (const resp of uploadResponse) {
          const json = await resp.json()

          setUploadedPublicityPhoto(() => [
            {
              faila_id: json.id,
            },
          ])
        }

        for (const fileEntry of publicityPhoto) {
          if (fileEntry.state === 'keep') {
            setUploadedPublicityPhoto((prevFormState) => [
              ...prevFormState,
              {
                faila_id: (fileEntry.file as FileEntry).id,
              },
            ])
          }
        }
        setIsLoading(false)
      })
      .catch(errorHandler)
  }

  const onLogoChange = (logo: FileListItem[]) => {
    setLogoImage(logo)
    setIsLoading(true)

    uploadFiles(logo)
      .then(async (uploadResponse: Response[]) => {
        for (const resp of uploadResponse) {
          const json = await resp.json()

          setUploadedLogoImage(() => [
            {
              faila_id: json.id,
            },
          ])
        }

        for (const fileEntry of publicityPhoto) {
          if (fileEntry.state === 'keep') {
            setUploadedLogoImage((prevFormState) => [
              ...prevFormState,
              {
                faila_id: (fileEntry.file as FileEntry).id,
              },
            ])
          }
        }
        setIsLoading(false)
      })
      .catch(errorHandler)
  }

  const onPlacePhotoChange = (placePhoto: FileListItem[], index: number) => {
    const copy = partnerPlaces.slice()

    copy[index] = {
      ...copy[index],
      placePhoto,
    }

    setPartnerPlaces(copy)
  }

  const onOnlineStoreAdd = () => {
    const interneta_veikals = registrationData.interneta_veikals
      ? registrationData.interneta_veikals.slice()
      : []

    interneta_veikals.push(NEW_ONLINE_STORE)

    setRegistrationData({
      ...registrationData,
      interneta_veikals,
    })
  }

  const onOnlineStoreRemove = (index: number) => {
    const interneta_veikals = registrationData.interneta_veikals
      ? registrationData.interneta_veikals.slice()
      : []

    interneta_veikals.splice(index, 1)

    setRegistrationData({
      ...registrationData,
      interneta_veikals,
    })
  }

  const onOnlineStoreTextFieldChange = (event: ChangeEvent<HTMLInputElement>, index: number) => {
    const interneta_veikals = registrationData.interneta_veikals
      ? registrationData.interneta_veikals.slice()
      : []

    interneta_veikals[index] = {
      ...interneta_veikals[index],
      [event.target.name]: event.target.value,
    }

    setRegistrationData({
      ...registrationData,
      interneta_veikals,
    })
  }

  const validateForm = () => {
    const errors: { [key: string]: string } = {}

    if (!registrationData.reģistrācijas_numurs) {
      errors['reģistrācijas_numurs'] = REQUIRED_ERROR_MESSAGE
    }

    if (!registrationData.nosaukums) {
      errors['nosaukums'] = REQUIRED_ERROR_MESSAGE
    }

    if (!registrationData.veids_kods) {
      errors['veids_kods'] = REQUIRED_ERROR_MESSAGE
    }

    if (editorRef.current && editorRef.current.getCharacterCount() > IMPORTANT_INFO_CHAR_LIMIT) {
      errors['apraksts'] = TOO_LONG_ERROR_MESSAGE
    }

    if (registrationData.mājas_lapa && !registrationData.mājas_lapa.match(REGEX_HOME_PAGE_FORMAT)) {
      errors['mājas_lapa'] = INVALID_HOME_PAGE_URL
    }

    for (let i = 0; i < contactPersons.length; i++) {
      const contactPerson = contactPersons[i]

      if (!contactPerson.personas_kods) {
        errors['personas_kods' + i] = REQUIRED_ERROR_MESSAGE
      }

      if (contactPerson.personas_kods && !contactPerson.personas_kods.match(REGEX_PERSON_CODE)) {
        errors['personas_kods' + i] = INVALID_PERSONAL_NUMBER
      }

      if (!contactPerson.telefons) {
        errors['telefons' + i] = REQUIRED_ERROR_MESSAGE
      }

      if (!contactPerson.epasts) {
        errors['epasts' + i] = REQUIRED_ERROR_MESSAGE
      }

      if (contactPerson.epasts && !contactPerson.epasts.match(REGEX_EMAIL_FORMAT)) {
        errors['epasts' + i] = INVALID_EMAIL_FORMAT
      }

      if (!contactPerson.loma_kods) {
        errors['loma_kods' + i] = REQUIRED_ERROR_MESSAGE
      }
    }

    for (let i = 0; i < discounts.length; i++) {
      const discount = discounts[i]

      if (!discount.skaits) {
        errors['skaits' + i] = REQUIRED_ERROR_MESSAGE
      }

      if (!discount.mērvienība_kods) {
        errors['mērvienība_kods' + i] = REQUIRED_ERROR_MESSAGE
      }

      if (!discount.apraksts) {
        errors['apraksts' + i] = REQUIRED_ERROR_MESSAGE
      }
    }

    setFormErrors({
      ...formErrors,
      ...errors,
    })

    return Object.keys(errors).length
  }

  const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    const isInvalid = validateForm()

    if (isInvalid) {
      return
    }

    setIsLoading(true)

    const requestData: AggregatedPartnerNewRequest = {
      ...registrationData,
      apraksts: editorRef.current ? editorRef.current.getText() : '',
      atlaide: discounts,
      persona: contactPersons,
    }

    for (const partnerPlace of partnerPlaces) {
      const placeRequest: AggregatedPartnerPlaceRequest = {
        adrese: partnerPlace.adrese,
        komentārs: partnerPlace.komentārs,
      }

      if (partnerPlace.placePhoto) {
        try {
          const uploadResponse = await uploadFiles(partnerPlace.placePhoto)
          const json = await uploadResponse[0].json()

          if (json.id) {
            placeRequest.faila_id = json.id
          }
        } catch (error) {
          errorHandler(error)
        }
      }
      requestData.vieta?.push(placeRequest)
    }

    if (uploadedPublicityPhoto.length) {
      requestData.foto_id = uploadedPublicityPhoto[0].faila_id
    }

    if (uploadedLogoImage.length) {
      requestData.foto_id = uploadedLogoImage[0].faila_id
    }

    aggregatedPartnerRegisterMutation(requestData)
  }

  const onCancel = () => {
    navigate('/')
  }

  return (
    <Box onSubmit={onSubmit} sx={{ width: '100%' }} component="form">
      <Typography variant="h1" textAlign="right" sx={{ mb: 2 }}>
        {t('partner.new-registration')}
      </Typography>
      <NavBar>
        <Button
          onClick={onCancel}
          disabled={isLoading || isInitializing}
          variant="outlined"
          sx={{ mr: 2 }}
        >
          {t('cancel')}
        </Button>
        <Button disabled={isLoading || isInitializing} variant="contained" type="submit">
          {t('register')}
        </Button>
      </NavBar>
      {isInitializing ? (
        <FullPageLoader />
      ) : (
        partnerTypeClassifier &&
        serviceGroupClassifier &&
        partnerContactPersonRoleClassifier &&
        partnerDiscountUnitClassifier && (
          <Grid2xN>
            <PartnerEditBasicInformation
              partnerData={registrationData}
              partnerTypeClassifier={partnerTypeClassifier!}
              errors={formErrors}
              onTextFieldChange={onRegistrationDataTextFieldChange}
              onSelectChange={onRegistrationDataSelectChange}
              handleRegistryData={fetchRegistryData}
              isRegistryDataLoading={isRegistryDataLoading}
            />
            <PartnerEditDetails
              partnerData={registrationData}
              onTextFieldChange={onRegistrationDataTextFieldChange}
              onAddressChange={onAddressChange}
            />
            <PartnerRegisterContactPersons
              contactPersons={contactPersons}
              contactPersonRoles={partnerContactPersonRoleClassifier!}
              errors={formErrors}
              onTextFieldChange={onContactPersonTextFieldChange}
              onSelectChange={onContactPersonSelectChange}
              addContactPerson={addContactPerson}
              removeContactPerson={removeContactPerson}
            />
            <PartnerEditAdditionalInformation
              partnerData={registrationData}
              serviceGroups={serviceGroupClassifier!}
              tags={tagClassifier!}
              publicityPhoto={publicityPhoto}
              logoImage={logoImage}
              errors={formErrors}
              ref={editorRef}
              onTextFieldChange={onRegistrationDataTextFieldChange}
              onServiceGroupChange={onServiceGroupChange}
              onTagChange={onTagChange}
              onPublicityPhotoChange={onPublicityPhotoChange}
              onLogoChange={onLogoChange}
              onOnlineStoreAdd={onOnlineStoreAdd}
              onOnlineStoreRemove={onOnlineStoreRemove}
              onOnlineStoreTextFieldChange={onOnlineStoreTextFieldChange}
            />
            <PartnerRegisterDiscount
              discounts={discounts}
              discountUnits={partnerDiscountUnitClassifier!}
              errors={formErrors}
              onTextFieldChange={onDiscountTextFieldChange}
              onSelectChange={onDiscountSelectChange}
              addDiscount={addDiscount}
              removeDiscount={removeDiscount}
            />
            <PartnerRegisterPlace
              partnerPlaces={partnerPlaces}
              onTextFieldChange={onPlacesTextFieldChange}
              addPlace={addPlace}
              removePlace={removePlace}
              onPlaceAddressChange={onPlaceAddressChange}
              onPlacePhotoChange={onPlacePhotoChange}
            />
          </Grid2xN>
        )
      )}
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
        <Box sx={{ display: 'flex' }}>
          <Button
            onClick={onCancel}
            disabled={isLoading || isInitializing}
            variant="outlined"
            sx={{ mr: 2 }}
          >
            {t('cancel')}
          </Button>
          <Button disabled={isLoading || isInitializing} variant="contained" type="submit">
            {t('register')}
          </Button>
        </Box>
      </Box>
    </Box>
  )
}
