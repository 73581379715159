import { Button, Box, SelectChangeEvent, Divider } from '@mui/material'
import { ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { FormSelectRow, FormTextRow, OutlinedContainer } from '../../../components'
import { PartnerDiscount, SelectItem } from '../../../interfaces'

interface PartnerRegisterDiscountProps {
  discounts: PartnerDiscount[]
  discountUnits: SelectItem[]
  errors: { [key: string]: string }
  onTextFieldChange: (event: ChangeEvent<HTMLInputElement>, index: number) => void
  onSelectChange: (event: SelectChangeEvent<string>, index: number) => void
  addDiscount: () => void
  removeDiscount: (index: number) => void
}

export const PartnerRegisterDiscount = ({
  discounts,
  discountUnits,
  errors,
  onTextFieldChange,
  onSelectChange,
  addDiscount,
  removeDiscount,
}: PartnerRegisterDiscountProps) => {
  const { t } = useTranslation()

  return (
    <OutlinedContainer label={t('discounts.offered-discounts')}>
      <>
        {discounts.map((discount, index) => (
          <Box
            key={index}
            sx={{
              '& > div:not(:first-of-type)': {
                mt: 2,
              },
            }}
          >
            <FormTextRow
              label={t('discounts.quantity')}
              value={discount.skaits}
              name="skaits"
              required={true}
              type="number"
              onChange={(event) => onTextFieldChange(event, index)}
              error={errors['skaits' + index]}
            />
            <FormSelectRow
              label={t('discounts.unit')}
              value={discount.mērvienība_kods}
              name="mērvienība_kods"
              selectOptions={discountUnits}
              onChange={(event) => onSelectChange(event, index)}
              required={true}
              error={errors['mērvienība_kods' + index]}
            />
            <FormTextRow
              label={t('discounts.description')}
              value={discount.apraksts}
              name="apraksts"
              multiline={true}
              rows={4}
              onChange={(event) => onTextFieldChange(event, index)}
              required={true}
              error={errors['apraksts' + index]}
            />
            <Box sx={{ mt: 2, textAlign: 'right' }}>
              <Button onClick={() => removeDiscount(index)} variant="outlined">
                {t('discounts.remove')}
              </Button>
            </Box>
            <Divider sx={{ mt: 2 }} />
          </Box>
        ))}
      </>
      <Box sx={{ mt: 2, textAlign: 'right' }}>
        <Button onClick={addDiscount} variant="contained">
          {t('discounts.add')}
        </Button>
      </Box>
    </OutlinedContainer>
  )
}
