import { Box } from '@mui/material'
import { format, parseISO } from 'date-fns'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { fetchComplaints } from '../../api/complaints'
import { NavBar, Table } from '../../components'
import { DEFAULT_DATE_FORMAT } from '../../constants'
import { Complaint } from '../../interfaces/complaints/Complaint'
import { useSnackbarStore } from '../../store'
import { parseError } from '../../utils'

export const Complaints: React.FC = () => {
  const { t } = useTranslation()
  const { showSnackbar } = useSnackbarStore()
  const { partnerId } = useParams<{ partnerId: string }>()

  const columns = [
    {
      field: 'apraksts',
      title: t('complaints.description'),
    },
    {
      field: 'atbilde',
      title: t('complaints.response'),
    },
    {
      field: 'pievienošanas_datums',
      title: t('complaints.add-date'),
      render: (rowData: Complaint) => {
        if (rowData.pievienošanas_datums) {
          return <>{format(parseISO(rowData.pievienošanas_datums), DEFAULT_DATE_FORMAT)}</>
        }

        return <></>
      },
    },
    {
      field: 'statuss_nosaukums',
      title: t('complaints.status'),
    },
  ]

  const { data, isFetching } = useQuery<Complaint[]>(
    'complaints-data',
    () => fetchComplaints({ sadarbības_partneris_id: partnerId || '' }),
    {
      onError: (error) => {
        showSnackbar(parseError(error, t), { severity: 'error' })
      },
    }
  )

  return (
    <Box sx={{ width: '100%' }}>
      <NavBar />
      <Table columns={columns} data={data || []} isLoading={isFetching} />
    </Box>
  )
}
