import {
  PartnerContactPerson,
  PartnerDiscount,
  PartnerOnlineStore,
  PartnerPlace,
} from '../interfaces'

export const DEFAULT_DATE_FORMAT = 'dd.MM.yyyy.'
export const DATE_PLACEHOLDER = 'dd.mm.gggg'

export const PHONE_PLACEHOLDER = '37100000000'

export const ACCEPTED_GENERIC_FILE_TYPES: { [key: string]: string | string[] } = {
  pdf: 'application/pdf',
  docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  png: 'image/png',
  jpg: 'image/jpeg',
  jpeg: 'image/jpeg',
  tiff: 'image/tiff',
  edoc: ['', 'application/vnd.etsi.asic-e+zip'],
}

export const ACCEPTED_IMAGE_FILE_TYPES: { [key: string]: string } = {
  jpg: 'image/jpeg',
  jpeg: 'image/jpeg',
  png: 'image/png',
  webp: 'image/webp',
}

export const ACCEPTED_PROMOTIONS_FILE_TYPES: { [key: string]: string } = {
  pdf: 'application/pdf',
  png: 'image/png',
  jpg: 'image/jpeg',
  jpeg: 'image/jpeg',
  tiff: 'image/tiff',
}

export const CUSTOM_RED = '#A10037'
export const CUSTOM_GREEN = '#00DC25'
export const CUSTOM_ORANGE = '#FF7300'

export const NEW_CONTACT_PERSON: PartnerContactPerson = {
  vārds: '',
  uzvārds: '',
  personas_kods: '',
  telefons: '',
  epasts: '',
  loma_kods: '',
  ir_bloķēts: false,
}

export const NEW_DISCOUNT: PartnerDiscount = {
  skaits: 0,
  apraksts: '',
  mērvienība_kods: '',
}

export const NEW_PARTNER_PLACE: PartnerPlace = {
  adrese: {
    koord_x: 0,
    zip: '',
    novads_nosaukums: '',
    pagasts_nosaukums: '',
    koord_y: 0,
    pilseta_atvk: '',
    code: 0,
    novads_atvk: '',
    adrese: '',
    pilseta_nosaukums: '',
    pagasts_atvk: '',
    vzd_adrese: '',
  },
  komentārs: '',
  placePhoto: [],
}

export const NEW_ONLINE_STORE: PartnerOnlineStore = {
  url: '',
  komentārs: '',
}

export const MUI_SELECT_OVERRIDE = {
  '.MuiSelect-select': {
    color: '#FFF!important',
  },
  '.MuiOutlinedInput-notchedOutline': {
    borderColor: '#FFF!important',
    borderWidth: '1px!important',
  },
  '.MuiInputLabel-root': {
    color: '#FFF!important',
  },
  '.MuiSvgIcon-root': {
    fill: '#FFF!important',
  },
  '.MuiSvgIcon-root.Mui-disabled': {
    display: 'none',
  },
  '.MuiSelect-select.Mui-disabled': {
    WebkitTextFillColor: '#FFF!important',
  },
}

export const IMPORTANT_INFO_CHAR_LIMIT = 1000

export const TAG_LIMIT = 6
export const GOODS_SERVICES_LIMIT = 3
