import { Action } from '@material-table/core'
import InfoIcon from '@mui/icons-material/Info'
import ReplyIcon from '@mui/icons-material/Reply'
import { Box } from '@mui/material'
import { format } from 'date-fns'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'

import { fetchInfoRequests } from '../../api'
import { NavBar, Table } from '../../components'
import { CUSTOM_ORANGE, DEFAULT_DATE_FORMAT } from '../../constants'
import { InfoRequest } from '../../interfaces'
import { useCurrentUserStore, usePartnerStore, useSnackbarStore } from '../../store'
import { parseError } from '../../utils'
import { PartnerStatusCode } from '../../interfaces/partner/PartnerStatusCode'
import { InfoRequestsDialog } from './InfoRequestsDialog'
import { InfoRequestsReplyDialog } from './InfoRequestsReplyDialog'

export const InfoRequests: React.FC = () => {
  const { t } = useTranslation()
  const { showSnackbar } = useSnackbarStore()
  const { partnerId } = useParams<{ partnerId: string }>()
  const { isApplicant } = useCurrentUserStore()
  const { currentPartnerStatus } = usePartnerStore()
  const isActionAllowed =
    !isApplicant || (isApplicant && currentPartnerStatus === PartnerStatusCode.Jauns)
  const [selectedInfoRequest, setSelectedInfoRequest] = useState<InfoRequest | undefined>(undefined)
  const [isInfoDialogOpen, setIsInfoDialogOpen] = useState<boolean>(false)
  const [isReplyDialogOpen, setIsReplyDialogOpen] = useState<boolean>(false)

  const columns = [
    {
      field: 'jautājums',
      title: t('info-requests.question'),
      width: '40%',
    },
    {
      field: 'atbilde',
      title: t('info-requests.response'),
      width: '40%',
    },
    {
      field: 'pievienošanas_laiks',
      title: t('complaints.add-date'),
      render: (rowData: InfoRequest) => {
        if (rowData.pievienošanas_laiks) {
          return format(new Date(rowData.pievienošanas_laiks), DEFAULT_DATE_FORMAT)
        }

        return <></>
      },
    },
  ]

  const handleInfoDialog = (_: React.MouseEvent, infoRequest: InfoRequest) => {
    setIsInfoDialogOpen(true)
    setSelectedInfoRequest(infoRequest)
  }

  const handleReplyDialog = (_: React.MouseEvent, infoRequest: InfoRequest) => {
    setIsReplyDialogOpen(true)
    setSelectedInfoRequest(infoRequest)
  }

  const actions = [
    {
      icon: () => <InfoIcon fontSize="small" htmlColor={CUSTOM_ORANGE} />,
      onClick: handleInfoDialog,
      tooltip: t('info-requests.full-complaint'),
    },
    {
      icon: () => <ReplyIcon fontSize="small" htmlColor={CUSTOM_ORANGE} />,
      onClick: handleReplyDialog,
      tooltip: t('info-requests.reply'),
    },
  ]

  const { data, isFetching, refetch } = useQuery<InfoRequest[]>(
    'info-requests',
    () => fetchInfoRequests({ sadarbības_partneris_id: partnerId || '' }),
    {
      onError: (error) => {
        showSnackbar(parseError(error, t), { severity: 'error' })
      },
      refetchOnWindowFocus: false,
    }
  )

  return (
    <Box sx={{ width: '100%' }}>
      <NavBar />
      <Table
        columns={columns}
        data={data || []}
        actions={isActionAllowed ? (actions as unknown as Action<InfoRequest>[]) : []}
        isLoading={isFetching}
      />
      {isInfoDialogOpen && selectedInfoRequest && (
        <InfoRequestsDialog
          infoRequest={selectedInfoRequest}
          onClose={() => setIsInfoDialogOpen(false)}
        />
      )}

      {isReplyDialogOpen && selectedInfoRequest && (
        <InfoRequestsReplyDialog
          infoRequest={selectedInfoRequest}
          onClose={() => setIsReplyDialogOpen(false)}
          onSuccess={refetch}
        />
      )}
    </Box>
  )
}
