import {
  Dialog,
  DialogActions,
  DialogTitle,
  Button,
  DialogContent,
  Box,
  TextField,
  Typography,
} from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import React, { ChangeEvent, forwardRef, useImperativeHandle, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { FileControl, FormRowGeneric, FormTextRow } from '../../../components'
import {
  ACCEPTED_PROMOTIONS_FILE_TYPES,
  DATE_PLACEHOLDER,
  DEFAULT_DATE_FORMAT,
} from '../../../constants'
import { EditorRef, FileListItem, Promotion } from '../../../interfaces'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { Editor } from '../../../components/Editor'

interface PromotionDialogProps {
  files: FileListItem[]
  title: string
  loading: boolean
  promotion: Promotion
  errors: { [key: string]: string }
  resetFormErrors?: (label: string) => void
  onClose: () => void
  onSubmit: () => void
  onFileChange: (file: FileListItem[]) => void
  onTextFieldChange: (event: ChangeEvent<HTMLInputElement>) => void
  onDateChange: (key: string, value: Date | null) => void
}

export const PromotionDialog = forwardRef(
  (
    {
      promotion,
      files,
      title,
      loading,
      errors,
      resetFormErrors,
      onClose,
      onSubmit,
      onFileChange,
      onTextFieldChange,
      onDateChange,
    }: PromotionDialogProps,
    ref
  ) => {
    const { t } = useTranslation()

    const editorRef = useRef<EditorRef>(null)

    useImperativeHandle(ref, () => ({
      getText() {
        if (editorRef.current) {
          return editorRef.current.getText()
        }

        return ''
      },
      getCharacterCount() {
        if (editorRef.current) {
          return editorRef.current.getCharacterCount()
        }

        return ''
      },
    }))

    return (
      <Dialog open={true} fullWidth maxWidth="md">
        <DialogTitle>{t(title)}</DialogTitle>
        <Box>
          <DialogContent sx={{ '& > div:not(:first-of-type)': { mt: 2 } }}>
            <FormTextRow
              label={t('name')}
              value={promotion.nosaukums}
              name="nosaukums"
              onChange={onTextFieldChange}
              multiline={false}
              required={true}
              error={errors['nosaukums']}
            />
            <FormRowGeneric label={t('discounts.description')} required={true}>
              <Editor
                name="apraksts"
                resetFormErrors={(label: string) => resetFormErrors?.(label)}
                value={promotion.apraksts}
                error={errors['apraksts']}
                ref={editorRef}
              />
            </FormRowGeneric>
            <FormRowGeneric label={t('promotions.appendix')} required={true}>
              <FileControl
                files={files}
                multiple={false}
                useLink={true}
                error={errors['pielikums']}
                acceptedUploadFileTypes={ACCEPTED_PROMOTIONS_FILE_TYPES}
                onFileChange={onFileChange}
              />
            </FormRowGeneric>
            <FormRowGeneric label={t('')} sx={{ mt: '0px !important' }}>
              <Typography sx={{ color: '#999' }}>{t('file-uplaod-note')}</Typography>
            </FormRowGeneric>
            <FormRowGeneric label={t('promotions.active_from')}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  inputFormat={DEFAULT_DATE_FORMAT}
                  value={promotion.aktīva_no}
                  minDate={new Date()}
                  onChange={(e) => onDateChange('aktīva_no', e)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      error={false}
                      inputProps={{ ...params.inputProps, placeholder: DATE_PLACEHOLDER }}
                    />
                  )}
                />
              </LocalizationProvider>
            </FormRowGeneric>
            <FormRowGeneric label={t('promotions.active_until')}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  inputFormat={DEFAULT_DATE_FORMAT}
                  value={promotion.aktīva_līdz}
                  minDate={new Date()}
                  onChange={(e) => onDateChange('aktīva_līdz', e)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      error={false}
                      inputProps={{ ...params.inputProps, placeholder: DATE_PLACEHOLDER }}
                    />
                  )}
                />
              </LocalizationProvider>
            </FormRowGeneric>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} variant="outlined" disabled={loading}>
              {t('cancel')}
            </Button>
            <Button onClick={onSubmit} variant="contained" disabled={loading}>
              {t('save')}
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    )
  }
)
