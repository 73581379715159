import { Navigate, RouteObject } from 'react-router-dom'
import { AuthGuard, BasicLayout, Layout, RoleGuard } from './containers'
import { PartnerRoleStatus } from './enums'
import {
  Home,
  Login,
  Logout,
  PartnerEdit,
  PartnerRegister,
  Discounts,
  Users,
  Places,
  Complaints,
  Promotions,
  CertificateVerification,
  InfoRequests,
} from './views'

export const routesConfig: RouteObject[] = [
  {
    path: '/',
    element: <Layout />,
    children: [
      {
        index: true,
        element: (
          <AuthGuard>
            <RoleGuard
              allowedRoles={[PartnerRoleStatus.KPN, PartnerRoleStatus.PIE, PartnerRoleStatus.SAB]}
            >
              <Home />
            </RoleGuard>
          </AuthGuard>
        ),
      },
      {
        path: '/register',
        element: (
          <AuthGuard>
            <PartnerRegister />
          </AuthGuard>
        ),
      },
      {
        path: '/edit/:partnerId',
        element: (
          <AuthGuard>
            <RoleGuard allowedRoles={[PartnerRoleStatus.KPN, PartnerRoleStatus.PIE]}>
              <PartnerEdit />
            </RoleGuard>
          </AuthGuard>
        ),
      },
      {
        path: '/users/:partnerId',
        element: (
          <AuthGuard>
            <RoleGuard allowedRoles={[PartnerRoleStatus.KPN, PartnerRoleStatus.PIE]}>
              <Users />
            </RoleGuard>
          </AuthGuard>
        ),
      },
      {
        path: '/discounts/:partnerId',
        element: (
          <AuthGuard>
            <RoleGuard
              allowedRoles={[PartnerRoleStatus.KPN, PartnerRoleStatus.SAB, PartnerRoleStatus.PIE]}
            >
              <Discounts />
            </RoleGuard>
          </AuthGuard>
        ),
      },
      {
        path: '/places/:partnerId',
        element: (
          <AuthGuard>
            <RoleGuard allowedRoles={[PartnerRoleStatus.KPN, PartnerRoleStatus.PIE]}>
              <Places />
            </RoleGuard>
          </AuthGuard>
        ),
      },
      {
        path: '/promotions/:partnerId',
        element: (
          <AuthGuard>
            <RoleGuard allowedRoles={[PartnerRoleStatus.KPN, PartnerRoleStatus.SAB]}>
              <Promotions />
            </RoleGuard>
          </AuthGuard>
        ),
      },
      {
        path: '/complaints/:partnerId',
        element: (
          <AuthGuard>
            <RoleGuard
              allowedRoles={[PartnerRoleStatus.KPN, PartnerRoleStatus.SAB, PartnerRoleStatus.PIE]}
            >
              <Complaints />
            </RoleGuard>
          </AuthGuard>
        ),
      },
      {
        path: '/info-requests/:partnerId',
        element: (
          <AuthGuard>
            <RoleGuard
              allowedRoles={[PartnerRoleStatus.KPN, PartnerRoleStatus.SAB, PartnerRoleStatus.PIE]}
            >
              <InfoRequests />
            </RoleGuard>
          </AuthGuard>
        ),
      },
      {
        path: '/certificate-verificaiton',
        element: (
          <AuthGuard>
            <RoleGuard allowedRoles={[PartnerRoleStatus.KPN, PartnerRoleStatus.KAS]}>
              <CertificateVerification />
            </RoleGuard>
          </AuthGuard>
        ),
      },
      {
        path: '*',
        element: <Navigate to="/" />,
      },
    ],
  },
  {
    path: '/login',
    element: (
      <BasicLayout>
        <Login />
      </BasicLayout>
    ),
  },
  {
    path: '/logout',
    element: (
      <BasicLayout>
        <Logout />
      </BasicLayout>
    ),
  },
]
