import { Box, Button, SelectChangeEvent, Typography } from '@mui/material'
import { ChangeEvent, FormEvent, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { fetchClassifier, getPartner, updatePartner, uploadFiles } from '../../api'
import { getRegistryData } from '../../api/partner/getRegistryData'
import {
  FullPageLoader,
  Grid2xN,
  PartnerEditBasicInformation,
  PartnerEditDetails,
  PartnerEditAdditionalInformation,
  NavBar,
} from '../../components'
import {
  GOODS_SERVICES_LIMIT,
  IMPORTANT_INFO_CHAR_LIMIT,
  INVALID_HOME_PAGE_URL,
  NEW_ONLINE_STORE,
  REGEX_HOME_PAGE_FORMAT,
  REQUIRED_ERROR_MESSAGE,
  TAG_LIMIT,
  TOO_LONG_ERROR_MESSAGE,
} from '../../constants'
import { ClassifierCode, FileState } from '../../enums'
import {
  FileListItem,
  PartnerRegistered,
  PartnerServiceGroup,
  EditorRef,
  PartnerTag,
} from '../../interfaces'
import { useSnackbarStore } from '../../store'
import { parseError } from '../../utils'

export const PartnerEdit = () => {
  const navigate = useNavigate()
  const showSnackbar = useSnackbarStore((state) => state.showSnackbar)
  const { partnerId } = useParams()
  const { t } = useTranslation()

  const editorRef = useRef<EditorRef>(null)

  const [registeredPartner, setRegisteredPartner] = useState<PartnerRegistered>(
    {} as PartnerRegistered
  )
  const [formErrors, setFormErrors] = useState<{ [key: string]: string }>({})
  const [isLoading, setIsLoading] = useState(false)
  const [isInitializing, setIsInitializing] = useState(true)

  const [publicityPhoto, setPublicityPhoto] = useState<FileListItem[]>([])
  const [logoImage, setLogoImage] = useState<FileListItem[]>([])

  const errorHandler = (error: unknown) => {
    setIsLoading(false)
    showSnackbar(parseError(error, t), { severity: 'error' })
  }

  const { data: partnerTypeClassifier, isLoading: loadingPartnerTypeClassifier } = useQuery(
    'partner-type-classifier',
    () => fetchClassifier({ klasifikatora_kods: ClassifierCode.PARTNERA_VEIDS }),
    {
      refetchOnWindowFocus: false,
      onError: errorHandler,
    }
  )

  const { data: serviceGroupClassifier, isLoading: loadingServiceGroupClassifier } = useQuery(
    'service-group-classifier',
    () => fetchClassifier({ klasifikatora_kods: ClassifierCode.PAKALPOJUMU_GRUPA }),
    {
      refetchOnWindowFocus: false,
      onError: errorHandler,
    }
  )

  const { data: tagClassifier, isLoading: loadingTagClassifier } = useQuery(
    'tag-classifier',
    () => fetchClassifier({ klasifikatora_kods: ClassifierCode.BIRKA }),
    {
      refetchOnWindowFocus: false,
      onError: errorHandler,
    }
  )

  const { isLoading: loadingPartner, refetch } = useQuery<PartnerRegistered>(
    'current-partner',
    () => getPartner(partnerId as string),
    {
      enabled: false,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        if (!data) {
          setIsLoading(false)
          showSnackbar(t('partner.not-found'), { severity: 'warning' })
          navigate('/')
          return
        }

        if (data.foto) {
          setPublicityPhoto([
            {
              file: data.foto,
              state: FileState.Keep,
            },
          ])
        }

        if (data.logotips) {
          setLogoImage([
            {
              file: data.logotips,
              state: FileState.Keep,
            },
          ])
        }

        setRegisteredPartner(data)
      },
      onError: (error) => {
        // TODO: should handle 404 when API is fixed

        showSnackbar(parseError(error, t), { severity: 'error' })
        navigate('/')
      },
    }
  )

  const { refetch: fetchRegistryData, isFetching: isRegistryDataLoading } = useQuery(
    'registry-data',
    () => getRegistryData(registeredPartner.reģistrācijas_numurs),
    {
      onSuccess: (data) => {
        const { address, legalForm, name } = data

        setRegisteredPartner({
          ...registeredPartner,
          juridiskā_adrese: {
            adrese: address,
          },
          veids_kods: legalForm,
          nosaukums: name,
          juridiskais_nosaukums: name,
        })
      },
      onError: errorHandler,
      retry: false,
      refetchOnWindowFocus: false,
      enabled: false,
    }
  )

  const { mutate: updateMutation } = useMutation(
    (partnerData: PartnerRegistered) => updatePartner(partnerData),
    {
      onSuccess: async (response: PartnerRegistered) => {
        showSnackbar(t('partner.updated'))
        navigate('/')
      },
      onError: errorHandler,
    }
  )

  useEffect(() => {
    if (partnerId) {
      refetch()
    }
  }, [partnerId, refetch])

  useEffect(() => {
    setIsInitializing(
      loadingPartner ||
        loadingPartnerTypeClassifier ||
        loadingServiceGroupClassifier ||
        loadingTagClassifier
    )
  }, [
    loadingPartner,
    loadingPartnerTypeClassifier,
    loadingServiceGroupClassifier,
    loadingTagClassifier,
  ])

  const validateForm = () => {
    const errors: { [key: string]: string } = {}

    if (!registeredPartner.reģistrācijas_numurs) {
      errors['reģistrācijas_numurs'] = REQUIRED_ERROR_MESSAGE
    }

    if (!registeredPartner.nosaukums) {
      errors['nosaukums'] = REQUIRED_ERROR_MESSAGE
    }

    if (!registeredPartner.veids_kods) {
      errors['veids_kods'] = REQUIRED_ERROR_MESSAGE
    }

    if (
      registeredPartner.mājas_lapa &&
      !registeredPartner.mājas_lapa.match(REGEX_HOME_PAGE_FORMAT)
    ) {
      errors['mājas_lapa'] = INVALID_HOME_PAGE_URL
    }

    if (editorRef.current && editorRef.current.getCharacterCount() > IMPORTANT_INFO_CHAR_LIMIT) {
      errors['apraksts'] = TOO_LONG_ERROR_MESSAGE
    }

    setFormErrors({
      ...formErrors,
      ...errors,
    })

    return Object.keys(errors).length
  }

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    const isInvalid = validateForm()

    if (isInvalid) {
      return
    }

    setIsLoading(true)

    const newPublicityPhotoFiles = publicityPhoto.filter((image) => image.state === FileState.New)
    const newLogoFiles = logoImage.filter((image) => image.state === FileState.New)

    Promise.all([uploadFiles(newPublicityPhotoFiles), uploadFiles(newLogoFiles)])
      .then(async ([uploadedPublicityPhoto, uploadedLogoImage]) => {
        const requestData = {
          ...registeredPartner,
          apraksts: editorRef.current ? editorRef.current.getText() : '',
        }

        if (uploadedPublicityPhoto.length) {
          const json = await uploadedPublicityPhoto[0].json()

          if (json.id) {
            requestData.foto_id = json.id
          }
        } else {
          const removedLogoFiles = logoImage.filter((image) => image.state === FileState.Delete)

          if (removedLogoFiles.length) {
            requestData.foto_id = undefined
          } else {
            requestData.foto_id = requestData.foto?.id || undefined
          }
        }

        if (uploadedLogoImage.length) {
          const json = await uploadedLogoImage[0].json()

          if (json.id) {
            requestData.logotips_id = json.id
          }
        } else {
          const removePublicityPhotoFiles = publicityPhoto.filter(
            (image) => image.state === FileState.Delete
          )

          if (removePublicityPhotoFiles.length) {
            requestData.logotips_id = undefined
          } else {
            requestData.logotips_id = requestData.logotips?.id || undefined
          }
        }

        updateMutation(requestData)
      })
      .catch(errorHandler)
  }

  const onCancel = () => {
    navigate('/')
  }

  const onPartnerDataTextFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
    setRegisteredPartner({
      ...registeredPartner,
      [event.target.name]: event.target.value,
    })

    setFormErrors({
      ...formErrors,
      [event.target.name]: '',
    })
  }

  const onPartnerDataSelectChange = (event: SelectChangeEvent<string>) => {
    setRegisteredPartner({
      ...registeredPartner,
      [event.target.name]: event.target.value,
    })

    setFormErrors({
      ...formErrors,
      [event.target.name]: '',
    })
  }

  const onAddressChange = (event: ChangeEvent<HTMLInputElement>) => {
    setRegisteredPartner({
      ...registeredPartner,
      juridiskā_adrese: {
        adrese: event.target.value,
      },
    })
  }

  const onServiceGroupChange = (event: SelectChangeEvent<string[]>) => {
    let serviceGroups: PartnerServiceGroup[] = []

    if (Array.isArray(event.target.value)) {
      serviceGroups = event.target.value.map((groupCode) => ({ grupa_kods: groupCode }))
    }

    if (serviceGroups.length > GOODS_SERVICES_LIMIT) {
      showSnackbar(t('errors.too-many-goods-services', { limit: GOODS_SERVICES_LIMIT }), {
        severity: 'info',
      })
      return
    }

    setRegisteredPartner({
      ...registeredPartner,
      pakalpojumu_grupa: serviceGroups,
    })
  }

  const onTagChange = (event: SelectChangeEvent<string[]>) => {
    let tags: PartnerTag[] = []

    if (Array.isArray(event.target.value)) {
      tags = event.target.value.map((tagCode) => ({ birka_kods: tagCode }))
    }

    if (tags.length > TAG_LIMIT) {
      showSnackbar(t('errors.too-many-tags', { limit: TAG_LIMIT }), { severity: 'info' })
      return
    }

    setRegisteredPartner({
      ...registeredPartner,
      pakalpojumu_birkas: tags,
    })
  }

  const onPublicityPhotoChange = (photo: FileListItem[]) => {
    setPublicityPhoto(photo)
  }

  const onLogoChange = (logo: FileListItem[]) => {
    setLogoImage(logo)
  }

  const onOnlineStoreAdd = () => {
    const interneta_veikals = registeredPartner.interneta_veikals
      ? registeredPartner.interneta_veikals.slice()
      : []

    interneta_veikals.push(NEW_ONLINE_STORE)

    setRegisteredPartner({
      ...registeredPartner,
      interneta_veikals,
    })
  }

  const onOnlineStoreRemove = (index: number) => {
    const interneta_veikals = registeredPartner.interneta_veikals
      ? registeredPartner.interneta_veikals.slice()
      : []

    interneta_veikals.splice(index, 1)

    setRegisteredPartner({
      ...registeredPartner,
      interneta_veikals,
    })
  }

  const onOnlineStoreTextFieldChange = (event: ChangeEvent<HTMLInputElement>, index: number) => {
    const interneta_veikals = registeredPartner.interneta_veikals
      ? registeredPartner.interneta_veikals.slice()
      : []

    interneta_veikals[index] = {
      ...interneta_veikals[index],
      [event.target.name]: event.target.value,
    }

    setRegisteredPartner({
      ...registeredPartner,
      interneta_veikals,
    })
  }

  return (
    <Box onSubmit={onSubmit} sx={{ width: '100%' }} component="form">
      <Typography variant="h1" textAlign="right" sx={{ mb: 2 }}>
        {t('partner.edit')}
      </Typography>
      <NavBar>
        <Button
          onClick={onCancel}
          disabled={isLoading || isInitializing}
          variant="outlined"
          sx={{ mr: 2 }}
        >
          {t('cancel')}
        </Button>
        <Button disabled={isLoading || isInitializing} variant="contained" type="submit">
          {t('save')}
        </Button>
      </NavBar>
      {isInitializing ? (
        <FullPageLoader />
      ) : (
        partnerTypeClassifier &&
        serviceGroupClassifier &&
        registeredPartner.id && (
          <>
            <Grid2xN>
              <PartnerEditBasicInformation
                partnerData={registeredPartner}
                partnerTypeClassifier={partnerTypeClassifier!}
                errors={formErrors}
                onTextFieldChange={onPartnerDataTextFieldChange}
                onSelectChange={onPartnerDataSelectChange}
                handleRegistryData={fetchRegistryData}
                isRegistryDataLoading={isRegistryDataLoading}
              />
              <PartnerEditDetails
                partnerData={registeredPartner}
                onTextFieldChange={onPartnerDataTextFieldChange}
                onAddressChange={onAddressChange}
              />
              <PartnerEditAdditionalInformation
                partnerData={registeredPartner}
                serviceGroups={serviceGroupClassifier!}
                tags={tagClassifier!}
                publicityPhoto={publicityPhoto}
                logoImage={logoImage}
                ref={editorRef}
                errors={formErrors}
                onTextFieldChange={onPartnerDataTextFieldChange}
                onServiceGroupChange={onServiceGroupChange}
                onTagChange={onTagChange}
                onPublicityPhotoChange={onPublicityPhotoChange}
                onLogoChange={onLogoChange}
                onOnlineStoreAdd={onOnlineStoreAdd}
                onOnlineStoreRemove={onOnlineStoreRemove}
                onOnlineStoreTextFieldChange={onOnlineStoreTextFieldChange}
              />
            </Grid2xN>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
              <Box sx={{ display: 'flex' }}>
                <Button
                  onClick={onCancel}
                  disabled={isLoading || isInitializing}
                  variant="outlined"
                  sx={{ mr: 2 }}
                >
                  {t('cancel')}
                </Button>
                <Button disabled={isLoading || isInitializing} variant="contained" type="submit">
                  {t('save')}
                </Button>
              </Box>
            </Box>
          </>
        )
      )}
    </Box>
  )
}
