import axios from 'axios'

interface ComplaintsParams {
  sadarbības_partneris_id: string
}

export const fetchComplaints = async (params: ComplaintsParams) => {
  const response = await axios.get('/data/sadarbības_partnera_sūdzību_saraksts', { params })

  return response.data
}
