import { Box, Theme } from '@mui/material'
import { SystemStyleObject } from '@mui/system'

interface PageWrapProps {
  sx?: boolean | SystemStyleObject<Theme> | ((theme: Theme) => SystemStyleObject<Theme>)
  children: React.ReactElement | null
}

export const PageWrap = ({ sx, children }: PageWrapProps) => {
  return (
    <Box
      sx={[
        {
          position: 'relative',
          ml: 'auto',
          mr: 'auto',
          p: 2,
          width: '100%',
          maxWidth: '1600px',
          minHeight: 'calc(100% - 103px)',
          display: 'flex',
          boxSizing: 'border-box',
        },
        sx || {},
      ]}
    >
      {children}
    </Box>
  )
}
