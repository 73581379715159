import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'

import { FormTextRow } from '../../../components'
import { REQUIRED_ERROR_MESSAGE } from '../../../constants'
import { InfoRequest, ReplyInfoRequest } from '../../../interfaces'
import { replyInfoRequest } from '../../../api/infoRequests/replyIInfoRequest'
import { parseError } from '../../../utils'
import { useSnackbarStore } from '../../../store'

interface DiscountDialogProps {
  infoRequest: InfoRequest
  onClose: () => void
  onSuccess: () => void
}

export const InfoRequestsReplyDialog = ({
  infoRequest,
  onSuccess,
  onClose,
}: DiscountDialogProps) => {
  const { t } = useTranslation()
  const showSnackbar = useSnackbarStore((state) => state.showSnackbar)
  const [reply, setReply] = useState<string>('')
  const [isInvalid, setIsInvalid] = useState<string>('')

  const errorHandler = (error: unknown) => {
    showSnackbar(parseError(error, t), { severity: 'error' })
  }

  const { mutate: discountMutation } = useMutation(
    (infoRequest: ReplyInfoRequest) => replyInfoRequest(infoRequest),
    {
      onSuccess: () => {
        showSnackbar(t('info-requests.reply-succesful'))
        onSuccess()
        onClose()
      },
      onError: errorHandler,
    }
  )

  const onSubmit = () => {
    if (!reply) {
      setIsInvalid(REQUIRED_ERROR_MESSAGE)
      return
    }

    discountMutation({
      id: infoRequest.id,
      atbilde: reply,
    })
  }

  return (
    <Dialog open={true} fullWidth>
      <DialogTitle>{t('info-requests.reply')}</DialogTitle>
      <Box>
        <DialogContent sx={{ '& > div:not(:first-of-type)': { mt: 2 } }}>
          <FormTextRow
            label={t('info-requests.response')}
            value={reply}
            name="atbilde"
            onChange={(e) => {
              setIsInvalid('')
              setReply(e.target.value)
            }}
            multiline={true}
            rows={4}
            required={true}
            error={isInvalid}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} variant="outlined">
            {t('cancel')}
          </Button>
          <Button variant="contained" onClick={onSubmit}>
            {t('save')}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  )
}
