import { Navigate } from 'react-router-dom'
import { useCurrentUserStore } from '../../store'

interface AuthGuardProps {
  children: JSX.Element
}

export const AuthGuard = ({ children }: AuthGuardProps) => {
  const currentUser = useCurrentUserStore((state) => state.currentUser)

  if (currentUser) {
    return children
  }

  return <Navigate to="/login" replace />
}
