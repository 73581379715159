import create from 'zustand'
import { PartnerRoleStatus } from '../enums'
import { CurrentUser } from '../interfaces'

interface UserStoreState {
  currentUser: CurrentUser | undefined
  currentUserRole?: PartnerRoleStatus
  isContactPerson: boolean
  isRegistryWorker: boolean
  isApplicant: boolean
  isPublicRelationsSpecialist: boolean
}
interface UserStore extends UserStoreState {
  setCurrentUser: (nextUser: CurrentUser | undefined) => void
  setUserRole: (nextUserRole?: PartnerRoleStatus) => void
  resetUserStore: () => void
}

const initialState: UserStoreState = {
  currentUser: undefined,
  currentUserRole: undefined,
  isContactPerson: false,
  isRegistryWorker: false,
  isApplicant: false,
  isPublicRelationsSpecialist: false,
}

export const useCurrentUserStore = create<UserStore>((set) => ({
  ...initialState,
  setCurrentUser: (nextUser: CurrentUser | undefined) => {
    set(() => ({ currentUser: nextUser }))
  },
  setUserRole: (nextUserRole?: PartnerRoleStatus) => {
    set(() => ({ currentUserRole: nextUserRole }))
    switch (nextUserRole) {
      case PartnerRoleStatus.KPN: {
        set(() => ({ isContactPerson: true }))
        return
      }
      case PartnerRoleStatus.KAS: {
        set(() => ({ isRegistryWorker: true }))
        return
      }
      case PartnerRoleStatus.PIE: {
        set(() => ({ isApplicant: true }))
        return
      }
      case PartnerRoleStatus.SAB: {
        set(() => ({ isPublicRelationsSpecialist: true }))
        return
      }
    }
  },
  resetUserStore: () => {
    set(initialState)
  },
}))
