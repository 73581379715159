import axios from 'axios'
import { NavigateFunction } from 'react-router-dom'

export const initInterceptors = (navigate: NavigateFunction) => {
  // request interceptor
  axios.interceptors.request.use(
    (config) => {
      config.headers = {
        ...config.headers,
        'Content-Type': 'application/json',
      }

      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )

  // response interceptor
  axios.interceptors.response.use(
    (response) => {
      return response
    },
    (error) => {
      if (error.response.status === 401) {
        navigate('/login', { replace: true })
      }

      return Promise.reject(error)
    }
  )
}
