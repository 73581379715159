import {
  Grid,
  Typography,
  Select,
  FormControl,
  MenuItem,
  SelectChangeEvent,
  FormHelperText,
  useTheme,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { SelectItem } from '../../interfaces'
import { RequiredMarker } from '../RequiredMarker'

interface FormSelectRowProps {
  label: string
  value: string
  name: string
  selectOptions: SelectItem[]
  error?: string
  required?: boolean
  disabled?: boolean
  onChange: (event: SelectChangeEvent<string>) => void
}

export const FormSelectRow = ({
  label,
  value,
  name,
  selectOptions,
  error,
  required,
  disabled,
  onChange,
}: FormSelectRowProps) => {
  const { t } = useTranslation()
  const theme = useTheme()

  return (
    <Grid container sx={{ alignItems: 'center' }}>
      <Grid item xs={6}>
        <Typography variant="body1" sx={{ pr: 2, pb: '3px' }}>
          {label}
          {required && <RequiredMarker disabled={disabled} />}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <FormControl sx={{ width: '100%' }} disabled={disabled}>
          <Select value={value} name={name} onChange={onChange} size="small" error={!!error}>
            {selectOptions.map((option) => (
              <MenuItem key={option.code} value={option.code}>
                {option.name}
              </MenuItem>
            ))}
          </Select>
          {error && (
            <FormHelperText sx={{ color: theme.palette.error.main }}>{t(error)}</FormHelperText>
          )}
        </FormControl>
      </Grid>
    </Grid>
  )
}
