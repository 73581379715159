import { Box, Typography } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FormRowGeneric } from '../../../components'
import { PartnerRegistered } from '../../../interfaces'
import { PartnerStatusCode } from '../../../interfaces/partner/PartnerStatusCode'
import { useCurrentUserStore } from '../../../store'
import { AgreementUpload } from '../AgreementUpload'
import { GenerateAgreementDialog } from '../GenerateAgreementDialog'

interface GenerateAgreementProps {
  partner: PartnerRegistered
  onRefetch: () => void
}

export const GenerateAgreement = ({ partner, onRefetch }: GenerateAgreementProps) => {
  const [openGenerateDialog, setOpenGenerateDialog] = useState(false)
  const { isContactPerson, isApplicant } = useCurrentUserStore()
  const isFileUploadVisible =
    isContactPerson || (isApplicant && partner?.statuss_kods === PartnerStatusCode.Jauns)
  const { t } = useTranslation()

  const onClose = () => {
    setOpenGenerateDialog(false)
  }

  return (
    <>
      <FormRowGeneric useSemicolon label={t('files')}>
        {isFileUploadVisible ? (
          <Typography sx={{ ml: 0.5, color: '#999' }}>
            {t('partner.partner-contract-note')}
          </Typography>
        ) : (
          <></>
        )}
        <Box sx={{ mt: 2 }}>
          <AgreementUpload
            partner={partner}
            onRefetch={onRefetch}
            isFileUploadVisible={isFileUploadVisible}
          />
        </Box>
        {isFileUploadVisible ? (
          <Typography sx={{ ml: 0.5, color: '#999' }}>{t('file-uplaod-note')}</Typography>
        ) : (
          <></>
        )}
      </FormRowGeneric>
      {openGenerateDialog && <GenerateAgreementDialog onClose={onClose} />}
    </>
  )
}
