export const REGEX_PERSON_CODE = /^([0-3]\d{5})-?\d{5}$/
export const REGEX_HOME_PAGE_FORMAT =
  /^(http(s)?:\/\/)[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/
export const REGEX_ONLY_NUMBERS = /^[0-9]+$/
export const REGEX_EMAIL_FORMAT =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const INVALID_PERSONAL_NUMBER = 'errors.invalid-personal-number'
export const EXISTING_PERSONAL_NUMBER = 'errors.existing-personal-number'
export const REQUIRED_ERROR_MESSAGE = 'errors.required'
export const TOO_LONG_ERROR_MESSAGE = 'errors.too-long'
export const INVALID_HOME_PAGE_URL = 'errors.invalid-home-page-url'
export const INVALID_EMAIL_FORMAT = 'errors.incorrect-email'
