import { Action, Column } from '@material-table/core'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import { Box, Button, SelectChangeEvent } from '@mui/material'
import { ChangeEvent, FormEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { createDiscount, deleteDiscount, fetchClassifier, getDiscounts } from '../../api'
import { editDiscount } from '../../api/discount/editDiscount'
import { FullPageLoader, NavBar, Table } from '../../components'
import DiscountDeleteModal from '../../components/DiscountDeleteModal/DiscountDeleteModal'
import { CUSTOM_ORANGE, CUSTOM_RED, REQUIRED_ERROR_MESSAGE } from '../../constants'
import { ClassifierCode } from '../../enums'
import { Discount } from '../../interfaces'
import { DiscountStatusCode } from '../../interfaces/discount/DiscountStatusCode'
import { PartnerStatusCode } from '../../interfaces/partner/PartnerStatusCode'
import { useCurrentUserStore, usePartnerStore, useSnackbarStore } from '../../store'
import { parseError } from '../../utils'
import { getRowColor } from '../../utils/discountRowColor'
import { DiscountDialog } from './DiscountDialog'
import { getSortedMatchingDiscountArrays } from '../../utils/discountArrays'
import { DiscountViewModal } from '../../components/DiscountViewModal'

export const Discounts = () => {
  const { partnerId } = useParams()
  const { t } = useTranslation()
  const showSnackbar = useSnackbarStore((state) => state.showSnackbar)
  const { isApplicant } = useCurrentUserStore()
  const { currentPartnerStatus } = usePartnerStore()
  const isCurrentPartnerNew = currentPartnerStatus === PartnerStatusCode.Jauns
  const isActionAllowed = !isApplicant || (isApplicant && isCurrentPartnerNew)

  const [firstRun, setFirstRun] = useState(true)
  const [isInitializing, setIsInitializing] = useState(true)
  const [suggestedDiscounts, setSuggestedDiscounts] = useState<Discount[]>([])
  const [activeDiscounts, setActiveDiscounts] = useState<Discount[]>([])

  const [selectedDiscount, setSelectedDiscount] = useState<Discount>({} as Discount)
  const [selectedDeleteDiscount, setSelectedDeleteDiscount] = useState<Discount>({} as Discount)
  const [selectedViewDiscount, setSelectedViewDiscount] = useState<Discount>({} as Discount)

  const [formErrors, setFormErrors] = useState<{ [key: string]: string }>({})
  const [dialogTitle, setDialogTitle] = useState('')
  const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] = useState<boolean>(false)
  const [isViewDiscountModalOpen, setIsViewDiscountModalOpen] = useState<boolean>(false)

  const errorHandler = (error: unknown) => {
    showSnackbar(parseError(error, t), { severity: 'error' })
  }

  const { isLoading, refetch } = useQuery<Discount[]>(
    'discounts-list',
    () => getDiscounts(partnerId as string),
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        const { sortedActiveDiscounts, sortedSuggestedDiscounts } =
          getSortedMatchingDiscountArrays(data)
        setActiveDiscounts(sortedActiveDiscounts)
        setSuggestedDiscounts(sortedSuggestedDiscounts)
        setFirstRun(false)
      },
      onError: (error) => {
        setFirstRun(false)
        errorHandler(error as string)
      },
    }
  )

  const { data: partnerDiscountUnitClassifier, isLoading: loadingPartnerDiscountUnitClassifier } =
    useQuery(
      'partner-discount-unit-classifier',
      () => fetchClassifier({ klasifikatora_kods: ClassifierCode.MĒRVIENĪBA }),
      {
        refetchOnWindowFocus: false,
        onError: errorHandler,
      }
    )

  const { mutate: discountMutation, isLoading: creatingDiscount } = useMutation(
    (discount: Discount) => createDiscount(discount),
    {
      onSuccess: async (data: Discount) => {
        showSnackbar(t(data.aizstājamā_atlaide_id ? 'discounts.edited' : 'discounts.added-new'))
        refetch()
        onClose()
      },
      onError: errorHandler,
    }
  )

  const { mutate: editDiscountMutation, isLoading: editingDiscount } = useMutation(
    (discount: Discount) => editDiscount(discount),
    {
      onSuccess: async () => {
        showSnackbar(t('discounts.edited'))
        refetch()
        onClose()
      },
      onError: errorHandler,
    }
  )

  const { mutate: deleteDiscountMutation, isLoading: deletingDiscount } = useMutation(
    (discount: Discount) => deleteDiscount(discount),
    {
      onSuccess: async () => {
        showSnackbar(t('changes-saved'))
        refetch()
        onClose()
      },
      onError: errorHandler,
    }
  )

  useEffect(() => {
    setIsInitializing(firstRun || loadingPartnerDiscountUnitClassifier)
  }, [firstRun, loadingPartnerDiscountUnitClassifier])

  const columns = [
    {
      field: 'skaits',
      title: t('discounts.quantity'),
    },
    {
      field: 'mērvienība_nosaukums',
      title: t('discounts.unit'),
    },
    {
      field: 'apraksts',
      title: t('discounts.description'),
      width: '60%',
      cellStyle: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        maxWidth: '100px',
      },
    },
    {
      field: 'statuss_nosaukums',
      title: t('discounts.status'),
    },
  ]

  const actions = [
    (rowData: Discount) => ({
      icon: () => <EditIcon fontSize="small" htmlColor={CUSTOM_ORANGE} />,
      onClick: (event: MouseEvent, discount: Discount) => onUpdateDiscount(discount),
      tooltip: t('edit'),
      disabled: creatingDiscount || deletingDiscount || editingDiscount,
      hidden:
        !rowData.id ||
        rowData.hasMatchingDiscount ||
        rowData.statuss_kods === DiscountStatusCode.Piedāvāta,
    }),
    (rowData: Discount) => ({
      icon: () => <DeleteIcon fontSize="small" htmlColor={CUSTOM_RED} />,
      onClick: (event: MouseEvent, discount: Discount) => onDeleteDiscount(discount),
      tooltip: t('delete'),
      disabled: creatingDiscount || deletingDiscount || editingDiscount,
      hidden: !rowData.id || rowData.statuss_kods === DiscountStatusCode.Aktīva,
    }),
  ]

  const onAddDiscount = () => {
    setDialogTitle('discounts.new')
    setSelectedDiscount({
      skaits: 0,
      sadarbības_partneris_id: parseInt(partnerId as string, 10),
      statuss_kods: '' as DiscountStatusCode,
      apraksts: '',
      mērvienība_kods: '',
    })
  }

  const onUpdateDiscount = (discount: Discount) => {
    setDialogTitle('discounts.edit')
    setSelectedDiscount(discount)
  }

  const handleDeleteConfirmModal = () => {
    setIsConfirmDeleteModalOpen(false)
    setSelectedDeleteDiscount({} as Discount)
  }

  const onDeleteDiscount = (discount: Discount) => {
    setIsConfirmDeleteModalOpen(true)
    setSelectedDeleteDiscount(discount)
  }

  const closeDiscountViewModal = () => {
    setSelectedViewDiscount({} as Discount)
    setIsViewDiscountModalOpen(false)
  }

  const onDiscountView = (_?: React.MouseEvent, discount?: Discount) => {
    if (discount?.id) {
      setSelectedViewDiscount(discount || ({} as Discount))
      setIsViewDiscountModalOpen(true)
    }
  }

  const validateForm = () => {
    const errors: { [key: string]: string } = {}

    if (!selectedDiscount.skaits) {
      errors['skaits'] = REQUIRED_ERROR_MESSAGE
    }

    if (!selectedDiscount.mērvienība_kods) {
      errors['mērvienība_kods'] = REQUIRED_ERROR_MESSAGE
    }

    if (!selectedDiscount.apraksts) {
      errors['apraksts'] = REQUIRED_ERROR_MESSAGE
    }

    setFormErrors(errors)

    return Object.keys(errors).length
  }

  const onClose = () => {
    setDialogTitle('')
    setSelectedDiscount({} as Discount)
    setFormErrors({})
  }

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    const isInvalid = validateForm()

    if (isInvalid) {
      return
    }

    const { id: discountId, ...restDiscountProps } = selectedDiscount

    discountId
      ? isCurrentPartnerNew
        ? editDiscountMutation(selectedDiscount)
        : discountMutation({ ...restDiscountProps, aizstājamā_atlaide_id: discountId })
      : discountMutation(selectedDiscount)
  }

  const onDiscountTextFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
    const copy = {
      ...selectedDiscount,
      [event.target.name]: event.target.value,
    }

    // input returns quantity as string, but API expects it as number
    if (event.target.name === 'skaits') {
      if (event.target.value) {
        copy.skaits = parseInt(copy.skaits as unknown as string, 10)
      } else {
        copy.skaits = 0
      }
    }

    setSelectedDiscount(copy)

    setFormErrors({
      ...formErrors,
      [event.target.name]: '',
    })
  }

  const onDiscountUnitChange = (event: SelectChangeEvent<string>) => {
    setSelectedDiscount({
      ...selectedDiscount,
      [event.target.name]: event.target.value,
    })

    setFormErrors({
      ...formErrors,
      [event.target.name]: '',
    })
  }

  return (
    <Box sx={{ width: '100%' }}>
      <NavBar>
        {isActionAllowed ? (
          <Button
            onClick={onAddDiscount}
            variant="outlined"
            disabled={isInitializing || creatingDiscount || deletingDiscount || editingDiscount}
          >
            {t('add')}
          </Button>
        ) : null}
      </NavBar>
      <Box>
        {isInitializing ? (
          <FullPageLoader />
        ) : (
          <Box display="flex" width="100%">
            <Table
              columns={columns as unknown as Column<Discount>[]}
              actions={isActionAllowed ? (actions as unknown as Action<Discount>[]) : []}
              data={activeDiscounts}
              wrapperBoxStyles={{ width: '100%' }}
              rowStyle={(rowData) => ({
                color: getRowColor(rowData),
              })}
              options={{
                sorting: false,
              }}
              isLoading={isLoading}
              onRowClick={onDiscountView}
            />
            {suggestedDiscounts.some(({ id }) => id) && (
              <Table
                columns={columns as unknown as Column<Discount>[]}
                actions={isActionAllowed ? (actions as unknown as Action<Discount>[]) : []}
                data={suggestedDiscounts}
                wrapperBoxStyles={{ width: '100%', marginLeft: '20px' }}
                rowStyle={(rowData) => ({
                  color: getRowColor(rowData),
                })}
                options={{
                  sorting: false,
                }}
                isLoading={isLoading}
                onRowClick={onDiscountView}
              />
            )}
          </Box>
        )}
      </Box>
      {selectedDiscount.sadarbības_partneris_id ? (
        <DiscountDialog
          discount={selectedDiscount}
          title={dialogTitle}
          errors={formErrors}
          loading={creatingDiscount}
          discountUnits={partnerDiscountUnitClassifier!}
          onSubmit={onSubmit}
          onClose={onClose}
          onTextFieldChange={onDiscountTextFieldChange}
          onSelectChange={onDiscountUnitChange}
        />
      ) : null}
      {isConfirmDeleteModalOpen ? (
        <DiscountDeleteModal
          onConfirm={() => {
            deleteDiscountMutation(selectedDeleteDiscount)
            handleDeleteConfirmModal()
          }}
          onClose={handleDeleteConfirmModal}
          discountInfo={selectedDeleteDiscount}
        />
      ) : null}
      {isViewDiscountModalOpen ? (
        <DiscountViewModal onClose={closeDiscountViewModal} discountInfo={selectedViewDiscount} />
      ) : null}
    </Box>
  )
}
