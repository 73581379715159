import React from 'react'
import { Snackbar as SnackbarBase, Alert, SxProps, IconButton } from '@mui/material'
import { useSnackbarStore } from '../../store'
import CloseIcon from '@mui/icons-material/Close'

export const Snackbar = () => {
  const { open, message, configuration, closeSnackbar } = useSnackbarStore()

  const getSafeAreaOffset = () => {
    const sx: SxProps = {
      paddingRight: 'env(safe-area-inset-right, 0)',
      paddingLeft: 'env(safe-area-inset-left, 0)',
    }

    if (configuration.vertical === 'top') {
      sx['paddingTop'] = 'env(safe-area-inset-top, 0)'
    } else {
      sx['paddingBottom'] = 'env(safe-area-inset-bottom, 0)'
    }

    return sx
  }

  const action = (
    <React.Fragment>
      <IconButton size="small" aria-label="close" color="inherit" onClick={closeSnackbar}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  )

  return (
    <SnackbarBase
      anchorOrigin={{
        vertical: configuration.vertical,
        horizontal: configuration.horizontal,
      }}
      autoHideDuration={configuration.autoHideDuration}
      open={open}
      onClose={closeSnackbar}
      message={message}
      sx={getSafeAreaOffset}
      key={configuration.vertical + configuration.horizontal}
    >
      <Alert severity={configuration.severity} sx={{ width: '100%', alignItems: 'center' }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            fontSize: 16,
            alignItems: 'center',
          }}
        >
          {message} {action}
        </div>
      </Alert>
    </SnackbarBase>
  )
}
