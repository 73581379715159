import { Button, Box, SelectChangeEvent } from '@mui/material'
import { ChangeEvent, FormEvent, useState, MouseEvent, useEffect } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { createUser, fetchClassifier, getUsers, updateUser } from '../../api'
import { FullPageLoader, NavBar, Table } from '../../components'
import {
  CUSTOM_GREEN,
  CUSTOM_ORANGE,
  CUSTOM_RED,
  EXISTING_PERSONAL_NUMBER,
  INVALID_EMAIL_FORMAT,
  INVALID_PERSONAL_NUMBER,
  REGEX_EMAIL_FORMAT,
  REGEX_PERSON_CODE,
  REQUIRED_ERROR_MESSAGE,
} from '../../constants'
import { ClassifierCode } from '../../enums'
import { User } from '../../interfaces'
import { useCurrentUserStore, usePartnerStore, useSnackbarStore } from '../../store'
import { UserDialog } from './UserDialog'
import EditIcon from '@mui/icons-material/Edit'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import { Action } from '@material-table/core'
import { parseError } from '../../utils'
import { useTranslation } from 'react-i18next'
import { PartnerStatusCode } from '../../interfaces/partner/PartnerStatusCode'

export const Users = () => {
  const { partnerId } = useParams()
  const { t } = useTranslation()
  const showSnackbar = useSnackbarStore((state) => state.showSnackbar)
  const { isApplicant } = useCurrentUserStore()
  const { currentPartnerStatus } = usePartnerStore()
  const isActionAllowed =
    !isApplicant || (isApplicant && currentPartnerStatus === PartnerStatusCode.Jauns)

  const [firstRun, setFirstRun] = useState(true)
  const [isInitializing, setIsInitializing] = useState(true)

  const [selectedUser, setSelectedUser] = useState<User>({} as User)
  const [readOnlySelectedUser, setReadOnlySelectedUser] = useState<User | undefined>(undefined)
  const [formErrors, setFormErrors] = useState<{ [key: string]: string }>({})
  const [dialogTitle, setDialogTitle] = useState('')

  const columns = [
    {
      field: 'vārds',
      title: t('users.name'),
    },
    {
      field: 'uzvārds',
      title: t('users.surname'),
    },
    {
      field: 'loma_nosaukums',
      title: t('users.role'),
      width: '35%',
    },
    {
      field: 'telefons',
      title: t('users.phone'),
    },
    {
      field: 'epasts',
      title: t('users.email'),
    },
    {
      field: 'ir_bloķēts',
      title: t('users.status'),
      render: ({ ir_bloķēts }: User) => (ir_bloķēts ? 'Neaktīvs' : 'Aktīvs'),
    },
  ]

  const errorHandler = (error: unknown) => {
    showSnackbar(parseError(error, t), { severity: 'error' })
  }

  const {
    data: usersData,
    isLoading,
    refetch,
  } = useQuery<User[]>('user-list', () => getUsers(partnerId as string), {
    refetchOnWindowFocus: false,
    onSuccess: () => {
      setFirstRun(false)
    },
    onError: (error) => {
      setFirstRun(false)
      errorHandler(error as string)
    },
  })

  const {
    data: partnerContactPersonRoleClassifier,
    isLoading: loadingPartnerContactPersonRoleClassifier,
  } = useQuery(
    'partner-contact-person-role-classifier',
    () => fetchClassifier({ klasifikatora_kods: ClassifierCode.PARTNERA_PERSONAS_LOMA }),
    {
      refetchOnWindowFocus: false,
      onError: errorHandler,
    }
  )

  const { mutate: createUserMutation, isLoading: creatingUser } = useMutation(
    (user: User) => createUser(user),
    {
      onSuccess: async () => {
        showSnackbar(t('users.added-new'))
        refetch()
        onClose()
      },
      onError: errorHandler,
    }
  )

  const { mutate: updateUserMutation, isLoading: updatingUser } = useMutation(
    (user: User) => updateUser(user),
    {
      onSuccess: async () => {
        showSnackbar(t('changes-saved'))
        refetch()
        onClose()
      },
      onError: errorHandler,
    }
  )

  useEffect(() => {
    setIsInitializing(firstRun || loadingPartnerContactPersonRoleClassifier)
  }, [firstRun, loadingPartnerContactPersonRoleClassifier])

  const actions = [
    {
      icon: () => <EditIcon fontSize="small" htmlColor={CUSTOM_ORANGE} />,
      onClick: (event: MouseEvent, user: User) => onUpdateUser(user),
      tooltip: t('edit'),
      disabled: creatingUser || updatingUser,
    },
    (rowData: User) => {
      return {
        icon: () =>
          rowData.ir_bloķēts ? (
            <CheckCircleOutlineIcon fontSize="small" htmlColor={CUSTOM_GREEN} />
          ) : (
            <RemoveCircleOutlineIcon fontSize="small" htmlColor={CUSTOM_RED} />
          ),
        onClick: (event: MouseEvent, user: User) => onToggleUserStatus(user),
        tooltip: rowData.ir_bloķēts ? t('users.activate') : t('users.block'),
        disabled: creatingUser || updatingUser,
      }
    },
  ]

  const onAddUser = () => {
    setDialogTitle(t('users.new'))
    setSelectedUser({
      sadarbības_partneris_id: parseInt(partnerId as string, 10),
      vārds: '',
      uzvārds: '',
      epasts: '',
      telefons: '',
      personas_kods: '',
      loma_kods: '',
      ir_bloķēts: false,
    })
  }

  const onUpdateUser = (user: User) => {
    setDialogTitle(t('users.edit'))
    setReadOnlySelectedUser(user)
    setSelectedUser(user)
  }

  const onToggleUserStatus = (user: User) => {
    updateUserMutation({
      ...user,
      ir_bloķēts: !user.ir_bloķēts,
    })
  }

  const onUserTextFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedUser({
      ...selectedUser,
      [event.target.name]: event.target.value,
    })

    setFormErrors({
      ...formErrors,
      [event.target.name]: '',
    })
  }

  const onUserRoleChange = (event: SelectChangeEvent<string>) => {
    setSelectedUser({
      ...selectedUser,
      [event.target.name]: event.target.value,
    })

    setFormErrors({
      ...formErrors,
      [event.target.name]: '',
    })
  }

  const validateForm = () => {
    const errors: { [key: string]: string } = {}
    const existingPersonCodes = usersData?.map(({ personas_kods }) => personas_kods)

    if (!selectedUser.personas_kods) {
      errors['personas_kods'] = REQUIRED_ERROR_MESSAGE
    }

    if (selectedUser.personas_kods && !selectedUser.personas_kods.match(REGEX_PERSON_CODE)) {
      errors['personas_kods'] = INVALID_PERSONAL_NUMBER
    }

    if (
      selectedUser.personas_kods &&
      readOnlySelectedUser?.personas_kods !== selectedUser.personas_kods &&
      existingPersonCodes?.includes(selectedUser.personas_kods)
    ) {
      errors['personas_kods'] = EXISTING_PERSONAL_NUMBER
    }

    if (!selectedUser.telefons) {
      errors['tālrunis'] = REQUIRED_ERROR_MESSAGE
    }

    if (!selectedUser.epasts) {
      errors['epasts'] = REQUIRED_ERROR_MESSAGE
    }

    if (selectedUser.epasts && !selectedUser.epasts.match(REGEX_EMAIL_FORMAT)) {
      errors['epasts'] = INVALID_EMAIL_FORMAT
    }

    if (!selectedUser.loma_kods) {
      errors['loma_kods'] = REQUIRED_ERROR_MESSAGE
    }

    setFormErrors(errors)

    return Object.keys(errors).length
  }

  const onClose = () => {
    setDialogTitle('')
    setSelectedUser({} as User)
    setReadOnlySelectedUser(undefined)
    setFormErrors({})
  }

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    const isInvalid = validateForm()

    if (isInvalid) {
      return
    }

    if (selectedUser.id) {
      updateUserMutation(selectedUser)
    } else {
      createUserMutation(selectedUser)
    }
  }

  return (
    <Box sx={{ width: '100%' }}>
      <NavBar>
        {isActionAllowed ? (
          <Button
            onClick={onAddUser}
            variant="outlined"
            disabled={isInitializing || creatingUser || updatingUser}
          >
            {t('add')}
          </Button>
        ) : null}
      </NavBar>
      <Box>
        {isInitializing ? (
          <FullPageLoader />
        ) : usersData ? (
          <Table
            columns={columns}
            actions={isActionAllowed ? (actions as Action<User>[]) : []}
            data={usersData}
            isLoading={isLoading}
          />
        ) : (
          <></>
        )}
      </Box>
      {selectedUser.sadarbības_partneris_id ? (
        <UserDialog
          user={selectedUser}
          title={dialogTitle}
          contactPersonRoles={partnerContactPersonRoleClassifier!}
          errors={formErrors}
          loading={creatingUser || updatingUser}
          onTextFieldChange={onUserTextFieldChange}
          onSelectChange={onUserRoleChange}
          onClose={onClose}
          onSubmit={onSubmit}
        />
      ) : (
        <></>
      )}
    </Box>
  )
}
