import { Card, Typography, Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

export const NoPartnerBanner = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const onApply = () => {
    navigate('/register')
  }

  return (
    <Card
      variant="outlined"
      sx={{
        margin: '0 auto',
        pt: 4,
        pr: 2,
        pb: 4,
        pl: 2,
        maxWidth: '36rem',
        textAlign: 'center',
        alignSelf: 'center',
      }}
    >
      <Typography variant="h1">{t('no-partner.title')}</Typography>
      <Typography variant="body1" sx={{ mt: 2 }}>
        {t('no-partner.message')}
      </Typography>
      <Button onClick={onApply} variant="contained" sx={{ mt: 2 }}>
        {t('no-partner.apply')}
      </Button>
    </Card>
  )
}
