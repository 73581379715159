import { EditorContent, useEditor } from '@tiptap/react'
import Underline from '@tiptap/extension-underline'
import TextAlign from '@tiptap/extension-text-align'
import StarterKit from '@tiptap/starter-kit'
import { forwardRef, useImperativeHandle } from 'react'
import { EditorMenu } from './EditorMenu'
import { Box, FormHelperText, Typography, useTheme } from '@mui/material'
import CharacterCount from '@tiptap/extension-character-count'
import { IMPORTANT_INFO_CHAR_LIMIT } from '../../constants'
import { useTranslation } from 'react-i18next'

interface EditorProps {
  value: string | undefined
  error: string | undefined | null
  name?: string
  resetFormErrors?: (name: string) => void
}

export const Editor = forwardRef(({ value, error, name, resetFormErrors }: EditorProps, ref) => {
  const { t } = useTranslation()
  const theme = useTheme()

  const editor = useEditor({
    extensions: [
      StarterKit,
      Underline,
      TextAlign.configure({
        types: ['heading', 'paragraph'],
      }),
      CharacterCount.configure({
        limit: IMPORTANT_INFO_CHAR_LIMIT,
      }),
    ],
    content: value || '',
    onUpdate: () => {
      if (name) resetFormErrors?.(name)
    },
  })

  useImperativeHandle(ref, () => ({
    getText() {
      if (editor) {
        return editor.getHTML()
      }

      return ''
    },
    getCharacterCount() {
      if (editor) {
        return editor.storage.characterCount.characters()
      }

      return 0
    },
  }))

  return (
    <Box>
      <EditorMenu editor={editor} />
      <EditorContent editor={editor} />
      <Box sx={{ display: 'flex' }}>
        {error && editor && (
          <FormHelperText sx={{ color: theme.palette.error.main, mt: 1, ml: '14px' }}>
            {t(error)}
          </FormHelperText>
        )}
        {editor && (
          <Typography sx={{ mt: 1, ml: 'auto' }}>
            <Typography
              component="span"
              sx={{
                color:
                  editor.storage.characterCount.characters() > IMPORTANT_INFO_CHAR_LIMIT
                    ? theme.palette.error.main
                    : undefined,
              }}
            >
              {editor.storage.characterCount.characters()}
            </Typography>{' '}
            / {IMPORTANT_INFO_CHAR_LIMIT}
          </Typography>
        )}
      </Box>
    </Box>
  )
})
