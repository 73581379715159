import { FormControl, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MUI_SELECT_OVERRIDE } from '../../constants'
import { StorageKey } from '../../enums'

export const LanguageSelect = () => {
  const { i18n } = useTranslation()

  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language)

  const onChange = (event: SelectChangeEvent<string>) => {
    i18n.changeLanguage(event.target.value)
    localStorage.setItem(StorageKey.Language, event.target.value)
    setSelectedLanguage(event.target.value)
  }

  return (
    <FormControl sx={MUI_SELECT_OVERRIDE} size="small">
      <Select sx={{ width: '4rem' }} value={selectedLanguage} onChange={onChange}>
        <MenuItem value={'lv'}>LV</MenuItem>
        <MenuItem value={'en'}>EN</MenuItem>
      </Select>
    </FormControl>
  )
}
