import CancelIcon from '@mui/icons-material/Cancel'
import { Box, Dialog, DialogContent, Divider, Typography } from '@mui/material'
import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'

import { DEFAULT_DATE_FORMAT } from '../../../constants'
import { InfoRequest } from '../../../interfaces'
import { Complaint } from '../../../interfaces/complaints/Complaint'
import { fetchComplaints } from '../../../api/complaints'

interface DiscountDialogProps {
  infoRequest: InfoRequest
  onClose: () => void
}

export const InfoRequestsDialog = ({ infoRequest, onClose }: DiscountDialogProps) => {
  const { t } = useTranslation()
  const { partnerId } = useParams<{ partnerId: string }>()

  const { data } = useQuery<Complaint[]>('complaints-data', () =>
    fetchComplaints({ sadarbības_partneris_id: partnerId || '' })
  )

  const complaint = data?.find(
    (complaint) => complaint.id === infoRequest.sadarbības_partnera_sūdzības_id
  )

  return (
    <Dialog open={true} fullWidth maxWidth="md" onClose={onClose}>
      <Box mt={2} mr={2} display="flex" justifyContent="flex-end">
        <CancelIcon
          style={{ width: 32, height: 32, cursor: 'pointer' }}
          color="primary"
          onClick={onClose}
        />
      </Box>
      <Box>
        <DialogContent sx={{ '& > div:not(:first-of-type)': { mt: 2 }, paddingTop: 0 }}>
          {complaint && (
            <>
              <Box display="flex" justifyContent="center">
                <Typography fontSize="20px" fontWeight={700} paddingBottom="16px">
                  {t('info-requests.complaint-overview')}
                </Typography>
              </Box>
              <Box
                display="grid"
                gridTemplateColumns="repeat(2, max-content)"
                columnGap="30px"
                rowGap="12px"
              >
                <Typography>{t('info-requests.add-date')}</Typography>
                <Typography fontWeight={500}>
                  {complaint?.pievienošanas_datums
                    ? format(new Date(complaint?.pievienošanas_datums), DEFAULT_DATE_FORMAT)
                    : null}
                </Typography>
                <Typography>{t('info-requests.description')}</Typography>
                <Typography fontWeight={500}>{complaint?.apraksts}</Typography>
                <Typography>{t('info-requests.reply-user')}</Typography>
                <Typography fontWeight={500}>{complaint?.atbilde}</Typography>
                <Typography>{t('info-requests.status')}</Typography>
                <Typography fontWeight={500}>{complaint?.statuss_nosaukums}</Typography>
              </Box>
              <Divider sx={{ margin: '28px 0' }} />
            </>
          )}
          <Box display="flex" justifyContent="center">
            <Typography fontSize="20px" fontWeight={700} paddingBottom="16px">
              {t('info-requests.title')}
            </Typography>
          </Box>
          <Box
            display="grid"
            gridTemplateColumns="repeat(2, max-content)"
            columnGap="30px"
            rowGap="12px"
          >
            <Typography>{t('info-requests.add-date')}</Typography>
            <Typography fontWeight={500}>
              {infoRequest.pievienošanas_laiks
                ? format(new Date(infoRequest.pievienošanas_laiks), DEFAULT_DATE_FORMAT)
                : null}
            </Typography>
            <Typography>{t('info-requests.question')}</Typography>
            <Typography fontWeight={500}>{infoRequest.jautājums}</Typography>
            <Typography>{t('info-requests.response-time')}</Typography>
            <Typography fontWeight={500}>
              {infoRequest.atbildes_laiks
                ? format(new Date(infoRequest.atbildes_laiks), DEFAULT_DATE_FORMAT)
                : null}
            </Typography>
            <Typography>{t('info-requests.response')}</Typography>
            <Typography fontWeight={500}>{infoRequest.atbilde}</Typography>
          </Box>
        </DialogContent>
      </Box>
    </Dialog>
  )
}
