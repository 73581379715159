import create from 'zustand'
import { PartnerListItem } from '../interfaces'

interface PartnerStore {
  partnerList: PartnerListItem[] | undefined
  currentPartner: PartnerListItem | undefined
  currentPartnerStatus: string | undefined
  setPartnerList: (nextPartnerList: PartnerListItem[] | undefined) => void
  setCurrentPartner: (nextPartner: PartnerListItem | undefined) => void
  setCurrentPartnerStatus: (nextStatus: string | undefined) => void
}

export const usePartnerStore = create<PartnerStore>((set) => ({
  partnerList: undefined,
  currentPartner: undefined,
  currentPartnerStatus: undefined,
  setPartnerList: (nextPartnerList) => {
    set(() => ({ partnerList: nextPartnerList }))
  },
  setCurrentPartner: (nextPartner) => {
    set(() => ({ currentPartner: nextPartner }))
  },
  setCurrentPartnerStatus: (nextStatus) => {
    set(() => ({ currentPartnerStatus: nextStatus }))
  },
}))
