import { Discount } from '../interfaces'
import { DiscountStatusCode } from '../interfaces/discount/DiscountStatusCode'

export const getSortedMatchingDiscountArrays = (discounts: Discount[]) => {
  const sortedActiveDiscounts: Discount[] = []
  const sortedSuggestedDiscounts: Discount[] = []

  const suggestedDiscounts = discounts.filter(
    (discount) => discount.statuss_kods === DiscountStatusCode.Piedāvāta
  )
  const activeDiscounts = discounts.filter(
    (discount) => discount.statuss_kods === DiscountStatusCode.Aktīva
  )

  activeDiscounts.forEach((activeDiscount) => {
    const matchingSuggestedDiscount = suggestedDiscounts.find(
      (suggestedDiscount) => suggestedDiscount.aizstājamā_atlaide_id === activeDiscount.id
    )
    sortedSuggestedDiscounts.push(matchingSuggestedDiscount || ({} as Discount))
    sortedActiveDiscounts.push({
      ...activeDiscount,
      hasMatchingDiscount: !!matchingSuggestedDiscount,
    })
  })

  // Case for suggested discount without active discount reference
  suggestedDiscounts.forEach((suggestedDiscount) => {
    if (suggestedDiscount.aizstājamā_atlaide_id === null) {
      sortedSuggestedDiscounts.push(suggestedDiscount)
      sortedActiveDiscounts.push({} as Discount)
    }
  })

  return {
    sortedActiveDiscounts,
    sortedSuggestedDiscounts,
  }
}
