import {
  Dialog,
  DialogActions,
  DialogTitle,
  Button,
  DialogContent,
  SelectChangeEvent,
  Box,
} from '@mui/material'
import { ChangeEvent, FormEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { FormSelectRow, FormTextRow } from '../../../components'
import { Discount, SelectItem } from '../../../interfaces'

interface DiscountDialogProps {
  discount: Discount
  title: string
  loading: boolean
  errors: { [key: string]: string }
  discountUnits: SelectItem[]
  onClose: () => void
  onSubmit: (event: FormEvent<HTMLFormElement>) => void
  onTextFieldChange: (event: ChangeEvent<HTMLInputElement>) => void
  onSelectChange: (event: SelectChangeEvent<string>) => void
}

export const DiscountDialog = ({
  discount,
  title,
  loading,
  errors,
  discountUnits,
  onSubmit,
  onClose,
  onTextFieldChange,
  onSelectChange,
}: DiscountDialogProps) => {
  const { t } = useTranslation()

  return (
    <Dialog open={true} fullWidth>
      <DialogTitle>{t(title)}</DialogTitle>
      <Box onSubmit={onSubmit} component="form">
        <DialogContent sx={{ '& > div:not(:first-of-type)': { mt: 2 } }}>
          <FormTextRow
            label={t('discounts.quantity')}
            value={discount.skaits}
            name="skaits"
            onChange={onTextFieldChange}
            required={true}
            error={errors['skaits']}
          />
          <FormSelectRow
            label={t('discounts.unit')}
            value={discount.mērvienība_kods}
            name="mērvienība_kods"
            selectOptions={discountUnits}
            onChange={onSelectChange}
            required={true}
            error={errors['mērvienība_kods']}
          />
          <FormTextRow
            label={t('discounts.description')}
            value={discount.apraksts}
            name="apraksts"
            onChange={onTextFieldChange}
            multiline={true}
            rows={4}
            required={true}
            error={errors['apraksts']}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} variant="outlined" disabled={loading}>
            {t('cancel')}
          </Button>
          <Button variant="contained" type="submit" disabled={loading}>
            {t('save')}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  )
}
