import axios from 'axios'

interface ComplaintsParams {
  sadarbības_partneris_id: string
}

export const fetchInfoRequests = async (params: ComplaintsParams) => {
  const response = await axios.get('/data/sadarbības_partnera_informācijas_pieprasījumu_saraksts', {
    params,
  })

  return response.data
}
