import { MouseEvent } from 'react'
import MaterialTable, { Action, Column, Options } from '@material-table/core'
import { Box } from '@mui/material'
import { CSSProperties } from 'react'
import { useTranslation } from 'react-i18next'

const DEFAULT_PAGE_SIZE = 10

const sx = {
  root: {
    '& .MuiTable-root': {
      margin: 'auto',
      width: '95%',
    },
    '& .MuiPaper-root': {
      border: '1px solid rgb(97, 97, 97, .5)',
      borderRadius: '10px',
      filter:
        'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)) drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
    },
    '& .MuiTableCell-root': {
      padding: '8px 16px',
      height: '50px',
    },
    '& thead tr th': {
      position: 'relative',
      letterSpacing: '0.025em',
      lineHeight: '100%',
      fontSize: '0.875rem',
      color: '#616161',
      padding: '12px 16px !important',
    },
  },
}

interface TableProps<T extends object> {
  columns: Column<T>[]
  data: T[]
  actions?: Action<T>[]
  isLoading: boolean
  rowStyle?: (rowData: T) => CSSProperties
  wrapperBoxStyles?: CSSProperties | null
  options?: Options<T>
  onRowClick?: (
    event?: React.MouseEvent,
    rowData?: T,
    toggleDetailPanel?: (panelIndex?: number) => void
  ) => void
}

export const Table = <T extends object>({
  columns,
  data,
  actions,
  isLoading,
  rowStyle,
  wrapperBoxStyles = null,
  options,
  onRowClick,
}: TableProps<T>) => {
  const { t } = useTranslation()

  return (
    <Box sx={[sx.root, wrapperBoxStyles]}>
      <MaterialTable
        data={data}
        columns={columns}
        actions={actions}
        options={{
          filtering: false,
          search: false,
          pageSize: DEFAULT_PAGE_SIZE,
          actionsColumnIndex: columns.length,
          rowStyle: (rowData) => ({
            fontSize: '0.875rem',
            ...(typeof rowStyle === 'function' ? rowStyle(rowData) : rowStyle),
          }),
          ...options,
        }}
        onRowClick={onRowClick}
        isLoading={isLoading}
        localization={{
          pagination: {
            labelRowsPerPage: ' ',
            labelRowsSelect: t('table.label-rows-select'),
            labelDisplayedRows: `{from}-{to} ${t('table.from')} {count}`,
            firstTooltip: t('table.first-page'),
            nextTooltip: t('table.next-page'),
            previousTooltip: t('table.previous-page'),
            lastTooltip: t('table.last-page'),
          },
          body: {
            emptyDataSourceMessage: t('table.no-data'),
          },
          header: {
            actions: ' ',
          },
        }}
        components={{
          Toolbar: () => null,
          Groupbar: () => null,
        }}
      />
    </Box>
  )
}
