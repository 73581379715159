import { Grid, TextField, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { RequiredMarker } from '../RequiredMarker'

interface FormTextRowProps {
  label: string
  value?: string | number
  name?: string
  error?: string | null
  placeholder?: string
  required?: boolean
  disabled?: boolean
  type?: string
  multiline?: boolean
  rows?: number
  onChange: React.ChangeEventHandler<HTMLInputElement>
}

export const FormTextRow = ({
  label,
  value,
  name,
  error,
  placeholder,
  required,
  disabled,
  type,
  multiline,
  rows,
  onChange,
}: FormTextRowProps) => {
  const { t } = useTranslation()

  return (
    <Grid container sx={{ alignItems: 'center' }}>
      <Grid item xs={6}>
        <Typography variant="body1" sx={{ pr: 2, pb: '3px' }}>
          {label}
          {required && <RequiredMarker disabled={disabled} />}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <TextField
          value={value || ''}
          name={name}
          onChange={onChange}
          sx={{ width: '100%' }}
          size="small"
          type={type}
          placeholder={placeholder}
          error={!!error}
          helperText={error ? t(error) : undefined}
          multiline={multiline}
          rows={rows}
        />
      </Grid>
    </Grid>
  )
}
