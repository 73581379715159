import { Box, Button } from '@mui/material'
import { ChangeEvent, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import {
  getPromotions,
  uploadFiles,
  deletePromotion,
  newPromotion,
  updatePromotion,
} from '../../api'
import { FullPageLoader, NavBar, Table } from '../../components'
import {
  CUSTOM_ORANGE,
  CUSTOM_RED,
  DEFAULT_DATE_FORMAT,
  IMPORTANT_INFO_CHAR_LIMIT,
  REQUIRED_ERROR_MESSAGE,
  TOO_LONG_ERROR_MESSAGE,
} from '../../constants'
import { FileState } from '../../enums'
import { EditorRef, FileListItem, Promotion } from '../../interfaces'
import { useCurrentUserStore, usePartnerStore, useSnackbarStore } from '../../store'
import { parseError } from '../../utils'
import { PromotionDialog } from './PromotionDialog/PromotionDialog'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { Action } from '@material-table/core'
import { format, parseISO } from 'date-fns'
import { PartnerStatusCode } from '../../interfaces/partner/PartnerStatusCode'

export const Promotions = () => {
  const { t } = useTranslation()
  const { isApplicant } = useCurrentUserStore()
  const { currentPartnerStatus } = usePartnerStore()
  const isActionAllowed =
    !isApplicant || (isApplicant && currentPartnerStatus === PartnerStatusCode.Jauns)
  const editorRef = useRef<EditorRef>(null)
  const { partnerId } = useParams()
  const showSnackbar = useSnackbarStore((state) => state.showSnackbar)

  const [firstRun, setFirstRun] = useState(true)
  const [data, setData] = useState<Promotion[]>([])
  const [isInitializing, setIsInitializing] = useState(true)
  const [isDisabledSubmit, setIsDisabledSubmit] = useState(false)
  const [formErrors, setFormErrors] = useState<{ [key: string]: string }>({})
  const [selectedPromotion, setSelectedPromotion] = useState<Promotion>({} as Promotion)
  const [dialogTitle, setDialogTitle] = useState('')
  const [selectedPromotionFiles, setSelectedPromotionFiles] = useState<FileListItem[]>([])

  const errorHandler = (error: unknown) => {
    showSnackbar(parseError(error, t), { severity: 'error' })
  }

  const { isLoading, refetch } = useQuery<Promotion[]>('promotions-list', () => getPromotions(), {
    refetchOnWindowFocus: false,
    onSuccess: (resp) => {
      setFirstRun(false)
      setData(
        resp.filter((promotion) => promotion.sadarbības_partneris_id.toString() === partnerId)
      )
    },
    onError: (error) => {
      setFirstRun(false)
      errorHandler(error as string)
    },
  })

  const columns = [
    {
      field: 'nosaukums',
      title: t('promotions.name'),
    },
    {
      field: 'aktīva_no',
      title: t('promotions.active_from'),
      render: (rowData: Promotion) => {
        if (rowData.aktīva_no) {
          return <>{format(parseISO(rowData.aktīva_no), DEFAULT_DATE_FORMAT)}</>
        }

        return <></>
      },
    },
    {
      field: 'aktīva_līdz',
      title: t('promotions.active_until'),
      render: (rowData: Promotion) => {
        if (rowData.aktīva_līdz) {
          return <>{format(parseISO(rowData.aktīva_līdz), DEFAULT_DATE_FORMAT)}</>
        }

        return <></>
      },
    },
    {
      field: 'statuss_nosaukums',
      title: t('partner.status'),
    },
  ]

  const onAddPromotion = () => {
    setDialogTitle(t('promotions.new'))
    setSelectedPromotionFiles([])
    setSelectedPromotion({
      sadarbības_partneris_id: parseInt(partnerId as string, 10),
      komentārs: '',
      nosaukums: '',
      apraksts: '',
      aktīva_līdz: null,
      aktīva_no: null,
    })
  }

  const onFileChange = (file: FileListItem[]) => {
    setFormErrors({
      ...formErrors,
      pielikums: '',
    })
    setSelectedPromotionFiles(file)
  }

  const onClose = () => {
    setDialogTitle('')
    setSelectedPromotion({} as Promotion)
    setFormErrors({})
  }

  const onPromotionTextFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedPromotion({
      ...selectedPromotion,
      [event.target.name]: event.target.value,
    })

    resetFormErrors(event.target.name)
  }

  const onDateChange = (key: string, value: Date | null) => {
    setSelectedPromotion({
      ...selectedPromotion,
      [key]: value,
    })
  }

  const { mutate: createPromotionMutation, isLoading: creatingPromotion } = useMutation(
    (promotion: Promotion) => newPromotion(promotion),
    {
      onSuccess: async () => {
        showSnackbar(t('promotions.added-new'))
        refetch()
        onClose()
      },
      onError: errorHandler,
    }
  )

  const { mutate: updatePromotionMutation, isLoading: updatingPromotion } = useMutation(
    (promotion: Promotion) => updatePromotion(promotion),
    {
      onSuccess: async () => {
        showSnackbar(t('changes-saved'))
        refetch()
        onClose()
      },
      onError: errorHandler,
    }
  )

  const onUpdatePromotion = (promotion: Promotion) => {
    setDialogTitle(t('promotions.edit'))
    setSelectedPromotion(promotion)

    if (promotion.pielikums) {
      setSelectedPromotionFiles([
        {
          file: {
            nosaukums: promotion.pielikums.nosaukums || '',
            content_type: promotion.pielikums.content_type || '',
            sha256: promotion.pielikums.sha256 || '',
            id: promotion.pielikums.id || 0,
          },
          state: FileState.Keep,
        },
      ])
    } else {
      setSelectedPromotionFiles([])
    }
  }

  const { mutate: deletePromotionMutation } = useMutation(
    (promotion: Promotion) => deletePromotion(promotion),
    {
      onSuccess: async () => {
        showSnackbar(t('changes-saved'))
        refetch()
        onClose()
      },
      onError: errorHandler,
    }
  )

  const onDeletePromotion = (promotion: Promotion) => {
    deletePromotionMutation(promotion)
  }

  const actions = [
    {
      icon: () => <EditIcon fontSize="small" htmlColor={CUSTOM_ORANGE} />,
      onClick: (event: MouseEvent, promotion: Promotion) => onUpdatePromotion(promotion),
      tooltip: t('edit'),
      disabled: false,
    },
    {
      icon: () => <DeleteIcon fontSize="small" htmlColor={CUSTOM_RED} />,
      onClick: (event: MouseEvent, promotion: Promotion) => onDeletePromotion(promotion),
      tooltip: t('delete'),
      disabled: false,
    },
  ]

  const validateForm = () => {
    const errors: { [key: string]: string } = {}

    if (!selectedPromotion.nosaukums) {
      errors['nosaukums'] = REQUIRED_ERROR_MESSAGE
    }

    if (editorRef.current && editorRef.current.getCharacterCount() < 1) {
      errors['apraksts'] = REQUIRED_ERROR_MESSAGE
    }

    if (editorRef.current && editorRef.current.getCharacterCount() > IMPORTANT_INFO_CHAR_LIMIT) {
      errors['apraksts'] = TOO_LONG_ERROR_MESSAGE
    }

    if (selectedPromotionFiles.length < 1) {
      errors['pielikums'] = REQUIRED_ERROR_MESSAGE
    }

    setFormErrors({
      ...formErrors,
      ...errors,
    })

    return Object.keys(errors).length
  }

  const resetFormErrors = (name: string) => {
    setFormErrors({
      ...formErrors,
      [name]: '',
    })
  }

  const onSubmit = () => {
    const isInvalid = validateForm()

    if (isInvalid) {
      return
    }

    const newFiles = selectedPromotionFiles.filter((file) => file.state === FileState.New)

    setIsDisabledSubmit(true)

    uploadFiles(newFiles)
      .then(async (uploadedFiles) => {
        const active_from = format(
          selectedPromotion.aktīva_no ? new Date(selectedPromotion.aktīva_no!) : new Date(),
          'yyy-MM-dd'
        )
        const active_to = format(
          selectedPromotion.aktīva_līdz ? new Date(selectedPromotion.aktīva_līdz!) : new Date(),
          'yyy-MM-dd'
        )

        const requestData = {
          ...selectedPromotion,
          aktīva_no: active_from,
          aktīva_līdz: active_to,
          apraksts: editorRef.current ? editorRef.current.getText() : '',
        }

        if (uploadedFiles.length) {
          const json = await uploadedFiles[0].json()

          if (json.id) {
            requestData.pielikums_id = json.id
            requestData.pielikums = {
              id: json.id,
              nosaukums: json.filename,
              sha256: json.sha_256,
              content_type: json.content_type,
            }
          }
        } else {
          const removedFiles = selectedPromotionFiles.filter(
            (file) => file.state === FileState.Delete
          )

          /**
           * Since there can be only 1 file added,
           * its safe to check whether "removedFiles"
           * variable consists of some items, in that case
           * we can remove all parts for "pielikums" value
           */
          if (removedFiles.length) {
            requestData.pielikums_id = null
            requestData.pielikums = undefined
          }
        }

        if (selectedPromotion.id) {
          updatePromotionMutation(requestData)
        } else {
          createPromotionMutation(requestData)
        }
      })
      .finally(() => setIsDisabledSubmit(false))
  }

  useEffect(() => {
    setIsInitializing(firstRun)
  }, [firstRun])

  return (
    <Box sx={{ width: '100%' }}>
      <NavBar>
        {isActionAllowed ? (
          <Button onClick={onAddPromotion} variant="outlined" disabled={firstRun}>
            {t('add')}
          </Button>
        ) : null}
      </NavBar>
      <Box>
        {isInitializing ? (
          <FullPageLoader />
        ) : data ? (
          <Table
            columns={columns}
            actions={isActionAllowed ? (actions as Action<Promotion>[]) : []}
            data={data}
            isLoading={isLoading}
          />
        ) : (
          <></>
        )}
      </Box>
      {selectedPromotion.sadarbības_partneris_id && (
        <PromotionDialog
          promotion={selectedPromotion}
          files={selectedPromotionFiles}
          title={dialogTitle}
          ref={editorRef}
          errors={formErrors}
          loading={isDisabledSubmit || creatingPromotion || updatingPromotion}
          resetFormErrors={resetFormErrors}
          onClose={onClose}
          onSubmit={onSubmit}
          onFileChange={onFileChange}
          onTextFieldChange={onPromotionTextFieldChange}
          onDateChange={onDateChange}
        />
      )}
    </Box>
  )
}
