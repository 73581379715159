import { SelectChangeEvent, Typography } from '@mui/material'
import { ChangeEvent, forwardRef, useImperativeHandle, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import {
  FormTextRow,
  OutlinedContainer,
  FormSelectMultipleRow,
  FormRowGeneric,
  FileControl,
  PartnerEditOnlineStore,
} from '..'
import { ACCEPTED_IMAGE_FILE_TYPES } from '../../constants'
import { EditorRef, FileListItem, PartnerNew, SelectItem } from '../../interfaces'
import { Editor } from '../Editor'

interface PartnerEditAdditionalInfoProps {
  partnerData: PartnerNew
  serviceGroups: SelectItem[]
  tags: SelectItem[]
  publicityPhoto: FileListItem[]
  logoImage: FileListItem[]
  errors: { [key: string]: string }
  onTextFieldChange: (event: ChangeEvent<HTMLInputElement>) => void
  onServiceGroupChange: (event: SelectChangeEvent<string[]>) => void
  onTagChange: (event: SelectChangeEvent<string[]>) => void
  onPublicityPhotoChange: (photo: FileListItem[]) => void
  onLogoChange: (logo: FileListItem[]) => void
  onOnlineStoreAdd: () => void
  onOnlineStoreRemove: (index: number) => void
  onOnlineStoreTextFieldChange: (event: ChangeEvent<HTMLInputElement>, index: number) => void
}

export const PartnerEditAdditionalInformation = forwardRef(
  (
    {
      partnerData,
      serviceGroups,
      tags,
      publicityPhoto,
      logoImage,
      errors,
      onTextFieldChange,
      onServiceGroupChange,
      onTagChange,
      onPublicityPhotoChange,
      onLogoChange,
      onOnlineStoreAdd,
      onOnlineStoreRemove,
      onOnlineStoreTextFieldChange,
    }: PartnerEditAdditionalInfoProps,
    ref
  ) => {
    const { t } = useTranslation()

    const editorRef = useRef<EditorRef>(null)

    useImperativeHandle(ref, () => ({
      getText() {
        if (editorRef.current) {
          return editorRef.current.getText()
        }

        return ''
      },
      getCharacterCount() {
        if (editorRef.current) {
          return editorRef.current.getCharacterCount()
        }

        return ''
      },
    }))

    return (
      <OutlinedContainer label={t('partner.additional-information')}>
        <FormTextRow
          label={t('partner.home-page')}
          value={partnerData.mājas_lapa}
          name="mājas_lapa"
          onChange={onTextFieldChange}
          error={errors['mājas_lapa']}
        />
        <PartnerEditOnlineStore
          partnerData={partnerData}
          onAdd={onOnlineStoreAdd}
          onRemove={onOnlineStoreRemove}
          onTextFieldChange={onOnlineStoreTextFieldChange}
        />
        <FormSelectMultipleRow
          label={t('partner.service-groups')}
          name="pakalpojumu_grupa"
          value={partnerData.pakalpojumu_grupa.map((group) => group.grupa_kods)}
          onChange={onServiceGroupChange}
          selectOptions={serviceGroups}
        />
        <FormSelectMultipleRow
          label={t('partner.industry')}
          name="pakalpojumu_birkas"
          value={partnerData.pakalpojumu_birkas.map((group) => group.birka_kods)}
          onChange={onTagChange}
          selectOptions={tags}
        />
        <FormRowGeneric label={t('partner.important-info')}>
          <Editor value={partnerData.apraksts} error={errors['apraksts']} ref={editorRef} />
        </FormRowGeneric>
        <FormRowGeneric label={t('partner.publicity-photo')}>
          <FileControl
            files={publicityPhoto}
            multiple={false}
            useLink={true}
            acceptedUploadFileTypes={ACCEPTED_IMAGE_FILE_TYPES}
            onFileChange={onPublicityPhotoChange}
          />
        </FormRowGeneric>
        <FormRowGeneric label={t('')} sx={{ mt: '0px !important' }}>
          <Typography sx={{ ml: 0.5, color: '#999' }}>{t('file-uplaod-note')}</Typography>
        </FormRowGeneric>
        <FormRowGeneric label={t('partner.logo')}>
          <FileControl
            files={logoImage}
            multiple={false}
            useLink={true}
            acceptedUploadFileTypes={ACCEPTED_IMAGE_FILE_TYPES}
            onFileChange={onLogoChange}
          />
        </FormRowGeneric>
        <FormRowGeneric label={t('')} sx={{ mt: '0px !important' }}>
          <Typography sx={{ ml: 0.5, color: '#999' }}>{t('file-uplaod-note')}</Typography>
        </FormRowGeneric>
      </OutlinedContainer>
    )
  }
)
