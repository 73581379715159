import DeleteIcon from '@mui/icons-material/Delete'
import UploadIcon from '@mui/icons-material/Upload'
import { Box, Button, FormHelperText, List, ListItem, Typography, useTheme } from '@mui/material'
import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { ACCEPTED_GENERIC_FILE_TYPES } from '../../constants'
import { FileState } from '../../enums'
import { FileListItem } from '../../interfaces'
import { useSnackbarStore } from '../../store'
import { FileLink } from '../FileLink'

const sx = {
  fileItem: {
    mr: 2,
    width: '320px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}
interface FileControlProps {
  files: FileListItem[]
  multiple?: boolean
  useLink?: boolean
  isDeletable?: boolean
  error?: string | undefined | null
  onFileChange: (files: FileListItem[]) => void
  acceptedUploadFileTypes?: { [key: string]: string | string[] }
}

export const FileControl = ({
  multiple = true,
  useLink,
  onFileChange,
  files,
  error,
  acceptedUploadFileTypes = ACCEPTED_GENERIC_FILE_TYPES,
  isDeletable = true,
}: FileControlProps) => {
  const { t } = useTranslation()
  const theme = useTheme()

  const defaultFileTypes = acceptedUploadFileTypes
  const acceptedFileTypes = Object.keys(defaultFileTypes)

  const acceptedTypes = acceptedFileTypes.map((type) => `.${type}`).join(',')
  const lastType = acceptedFileTypes.pop()
  const acceptedTypesText = acceptedFileTypes.join(', ') + ` ${t('or')} ${lastType}`

  const fileUploadRef = useRef<HTMLInputElement>(null)
  const showSnackbar = useSnackbarStore((state) => state.showSnackbar)

  const selectFiles = () => {
    fileUploadRef && fileUploadRef.current?.click()
  }

  const isFileValid = (extension: string, type: string) => {
    if (defaultFileTypes[extension] == null) {
      return false
    }

    if (Array.isArray(defaultFileTypes[extension])) {
      return defaultFileTypes[extension].includes(type)
    }

    return defaultFileTypes[extension] === type
  }

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const newFiles: FileListItem[] = []

      for (const file of Array.from(event.target.files)) {
        const extension = file.name.split('.').pop()?.toLowerCase()

        if (!extension) {
          continue
        }

        if (isFileValid(extension, file.type)) {
          newFiles.push({
            file,
            state: FileState.New,
          })
        } else {
          showSnackbar(`${file.name} ${t('errors.invalid-file-format')}`, { severity: 'warning' })
        }
      }

      if (multiple) {
        onFileChange([...files, ...newFiles])
      } else {
        onFileChange([...newFiles])
      }
    }
  }

  const removeFile = (index: number) => {
    const filesCopy = [...files]

    if (filesCopy[index].file instanceof File) {
      filesCopy.splice(index, 1)
    } else {
      filesCopy[index] = {
        ...filesCopy[index],
        state: FileState.Delete,
      }
    }

    onFileChange(filesCopy)
  }

  return (
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <input
          ref={fileUploadRef}
          type="file"
          multiple={multiple}
          accept={acceptedTypes}
          hidden
          onChange={onChange}
        ></input>
        <Button variant="outlined" color="primary" sx={{ flexShrink: 0 }} onClick={selectFiles}>
          {multiple ? t('add-files') : t('add-file')} <UploadIcon />
        </Button>
        <Typography sx={{ ml: 2, color: '#999' }}>{acceptedTypesText}</Typography>
      </Box>
      <Box>
        {error && (
          <FormHelperText sx={{ color: theme.palette.error.main, mt: 1, ml: '14px' }}>
            {t(error)}
          </FormHelperText>
        )}
        <List>
          {files.map((entry, index) => {
            return (
              <Box key={index}>
                {entry.state === FileState.Delete ? (
                  <></>
                ) : (
                  <ListItem key={index} disableGutters>
                    <Box component="span" sx={sx.fileItem}>
                      {useLink ? (
                        entry.file instanceof File ? (
                          entry.file.name
                        ) : (
                          <FileLink file={entry.file} />
                        )
                      ) : entry.file instanceof File ? (
                        entry.file.name
                      ) : (
                        entry.file.nosaukums
                      )}
                    </Box>
                    {isDeletable && (
                      <Button onClick={() => removeFile(index)}>
                        <DeleteIcon />
                      </Button>
                    )}
                  </ListItem>
                )}
              </Box>
            )
          })}
        </List>
      </Box>
    </Box>
  )
}
