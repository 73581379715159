import { Link } from '@mui/material'
import { FileEntry } from '../../interfaces'

const { REACT_APP_HOST } = process.env

export const FileLink = ({ file }: { file: FileEntry }) => {
  return (
    <Link
      href={`${REACT_APP_HOST || window.location.origin}/api/v1/download/${file.id}/${file.sha256}`}
      {...(file.content_type === 'application/pdf'
        ? { target: '_blank', rel: 'noreferrer' }
        : { download: file.nosaukums })}
    >
      {file.nosaukums}
    </Link>
  )
}
