import { Button, Box, Divider, SelectChangeEvent } from '@mui/material'
import { ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { FormSelectRow, FormTextRow, OutlinedContainer } from '../../../components'
import { PHONE_PLACEHOLDER } from '../../../constants/config'
import { PartnerContactPerson, SelectItem } from '../../../interfaces'

interface PartnerRegisterContactPersonsProps {
  contactPersons: PartnerContactPerson[]
  contactPersonRoles: SelectItem[]
  errors: { [key: string]: string }
  onTextFieldChange: (event: ChangeEvent<HTMLInputElement>, index: number) => void
  onSelectChange: (event: SelectChangeEvent<string>, index: number) => void
  addContactPerson: () => void
  removeContactPerson: (index: number) => void
}

export const PartnerRegisterContactPersons = ({
  contactPersons,
  contactPersonRoles,
  errors,
  onTextFieldChange,
  onSelectChange,
  addContactPerson,
  removeContactPerson,
}: PartnerRegisterContactPersonsProps) => {
  const { t } = useTranslation()

  return (
    <OutlinedContainer label={t('users.contacts')}>
      <>
        {contactPersons.map((contactPerson, index) => (
          <Box
            key={index}
            sx={{
              '& > div:not(:first-of-type)': {
                mt: 2,
              },
            }}
          >
            <FormTextRow
              label={t('users.name')}
              value={contactPerson.vārds}
              name="vārds"
              onChange={(event) => onTextFieldChange(event, index)}
            />
            <FormTextRow
              label={t('users.surname')}
              value={contactPerson.uzvārds}
              name="uzvārds"
              onChange={(event) => onTextFieldChange(event, index)}
            />
            <FormTextRow
              label={t('users.personal-number')}
              value={contactPerson.personas_kods}
              name="personas_kods"
              onChange={(event) => onTextFieldChange(event, index)}
              required={true}
              error={errors['personas_kods' + index]}
            />
            <FormTextRow
              label={t('users.phone')}
              value={contactPerson.telefons}
              name="telefons"
              placeholder={PHONE_PLACEHOLDER}
              onChange={(event) => onTextFieldChange(event, index)}
              required={true}
              error={errors['telefons' + index]}
            />
            <FormTextRow
              label={t('users.email')}
              value={contactPerson.epasts}
              name="epasts"
              onChange={(event) => onTextFieldChange(event, index)}
              required={true}
              error={errors['epasts' + index]}
            />
            <FormSelectRow
              label={t('users.role')}
              value={contactPerson.loma_kods}
              name="loma_kods"
              selectOptions={contactPersonRoles}
              onChange={(event) => onSelectChange(event, index)}
              required={true}
              error={errors['loma_kods' + index]}
            />
            {index !== 0 && (
              <Box sx={{ mt: 2, textAlign: 'right' }}>
                <Button onClick={() => removeContactPerson(index)} variant="outlined">
                  {t('users.remove-contact')}
                </Button>
              </Box>
            )}
            <Divider sx={{ mt: 2 }} />
          </Box>
        ))}
      </>
      <Box sx={{ mt: 2, textAlign: 'right' }}>
        <Button onClick={addContactPerson} variant="contained">
          {t('users.add-contact')}
        </Button>
      </Box>
    </OutlinedContainer>
  )
}
