import { Button, Box, Divider } from '@mui/material'
import { ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { PartnerNew } from '../../interfaces'
import { FormRowGeneric } from '../FormRowGeneric'
import { FormTextRow } from '../FormTextRow'

interface PartnerEditOnlineStoreProps {
  partnerData: PartnerNew
  onAdd: () => void
  onRemove: (index: number) => void
  onTextFieldChange: (event: ChangeEvent<HTMLInputElement>, index: number) => void
}

export const PartnerEditOnlineStore = ({
  partnerData,
  onAdd,
  onRemove,
  onTextFieldChange,
}: PartnerEditOnlineStoreProps) => {
  const { t } = useTranslation()

  return (
    <>
      {partnerData.interneta_veikals?.map((store, index) => (
        <Box
          key={index}
          sx={{
            '& > div:not(:first-of-type)': {
              mt: 2,
            },
          }}
        >
          <FormRowGeneric label={t('partner.online-store')}>
            <></>
          </FormRowGeneric>
          <FormTextRow
            label="URL"
            value={store.url}
            name="url"
            onChange={(event) => onTextFieldChange(event, index)}
          />
          <FormTextRow
            label={t('comment')}
            value={store.komentārs}
            name="komentārs"
            multiline={true}
            rows={4}
            onChange={(event) => onTextFieldChange(event, index)}
          />
          <Box sx={{ textAlign: 'right' }}>
            <Button onClick={() => onRemove(index)} variant="outlined">
              {t('partner.remove-online-store')}
            </Button>
          </Box>
          <Divider sx={{ mt: 2 }} />
        </Box>
      ))}
      <FormRowGeneric label={t('partner.online-store')}>
        <Box sx={{ textAlign: 'right' }}>
          <Button onClick={onAdd} variant="contained">
            {t('partner.add-online-store')}
          </Button>
        </Box>
      </FormRowGeneric>
    </>
  )
}
