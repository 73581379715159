import {
  FormControl,
  Grid,
  Select,
  SelectChangeEvent,
  Typography,
  MenuItem,
  Checkbox,
} from '@mui/material'
import { useMemo } from 'react'
import { SelectItem } from '../../interfaces'
import { RequiredMarker } from '../RequiredMarker'

interface FormSelectMultipleRowProps {
  label: string
  value: string[]
  name: string
  selectOptions: SelectItem[]
  required?: boolean
  disabled?: boolean
  onChange: (event: SelectChangeEvent<string[]>) => void
}

export const FormSelectMultipleRow = ({
  label,
  value,
  name,
  selectOptions,
  required,
  disabled,
  onChange,
}: FormSelectMultipleRowProps) => {
  const labels = useMemo(() => {
    const map: { [key: string]: string } = {}

    for (const option of selectOptions) {
      map[option.code] = option.name
    }

    return map
  }, [selectOptions])

  return (
    <Grid container sx={{ alignItems: 'center' }}>
      <Grid item xs={6}>
        <Typography variant="body1" sx={{ pr: 2, pb: '3px' }}>
          {label}
          {required && <RequiredMarker disabled={disabled} />}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <FormControl sx={{ width: '100%' }} disabled={disabled}>
          <Select
            value={value}
            name={name}
            onChange={onChange}
            size="small"
            multiple
            renderValue={(selected) => selected.map((value) => labels[value]).join(', ')}
          >
            {selectOptions.map((option) => (
              <MenuItem key={option.code} value={option.code}>
                <Checkbox checked={value.indexOf(option.code) > -1} size="small" />
                {option.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  )
}
