import { Box, Button } from '@mui/material'
import { ChangeEvent, FormEvent, useEffect, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { createPlace, deletePlace, getPlaces, updatePlace, uploadFiles } from '../../api'
import { FileLink, FullPageLoader, NavBar, Table } from '../../components'
import { FileListItem, PlaceAddress, Place } from '../../interfaces'
import { useCurrentUserStore, usePartnerStore, useSnackbarStore } from '../../store'
import { parseError } from '../../utils'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { CUSTOM_ORANGE, CUSTOM_RED } from '../../constants'
import { Action } from '@material-table/core'
import { PlaceDialog } from './PlaceDialog'
import { FileState } from '../../enums'
import { useTranslation } from 'react-i18next'
import { PartnerStatusCode } from '../../interfaces/partner/PartnerStatusCode'

export const Places = () => {
  const { partnerId } = useParams()
  const { t } = useTranslation()
  const showSnackbar = useSnackbarStore((state) => state.showSnackbar)
  const { isApplicant } = useCurrentUserStore()
  const { currentPartnerStatus } = usePartnerStore()
  const isActionAllowed =
    !isApplicant || (isApplicant && currentPartnerStatus === PartnerStatusCode.Jauns)

  const [firstRun, setFirstRun] = useState(true)
  const [isInitializing, setIsInitializing] = useState(true)
  const [isDisabledSubmit, setIsDisabledSubmit] = useState(false)

  const [selectedPlace, setSelectedPlace] = useState<Place>({} as Place)
  const [selectedPlaceFiles, setSelectedPlaceFiles] = useState<FileListItem[]>([])
  const [dialogTitle, setDialogTitle] = useState('')

  const errorHandler = (error: unknown) => {
    showSnackbar(parseError(error, t), { severity: 'error' })
  }

  const { data, isLoading, refetch } = useQuery<Place[]>(
    'places-list',
    () => getPlaces(partnerId as string),
    {
      refetchOnWindowFocus: false,
      onSuccess: () => {
        setFirstRun(false)
      },
      onError: (error) => {
        setFirstRun(false)
        errorHandler(error as string)
      },
    }
  )

  const { mutate: createPlaceMutation, isLoading: creatingPlace } = useMutation(
    (place: Place) => createPlace(place),
    {
      onSuccess: async () => {
        showSnackbar(t('places.added-new'))
        refetch()
        onClose()
      },
      onError: errorHandler,
    }
  )

  const { mutate: updatePlaceMutation, isLoading: updatingPlace } = useMutation(
    (place: Place) => updatePlace(place),
    {
      onSuccess: async () => {
        showSnackbar(t('changes-saved'))
        refetch()
        onClose()
      },
      onError: errorHandler,
    }
  )

  const { mutate: deletePlaceMutation, isLoading: deletingPlace } = useMutation(
    (place: Place) => deletePlace(place),
    {
      onSuccess: async () => {
        showSnackbar(t('changes-saved'))
        refetch()
        onClose()
      },
      onError: errorHandler,
    }
  )

  useEffect(() => {
    setIsInitializing(firstRun)
  }, [firstRun])

  const columns = [
    {
      field: 'adrese',
      title: t('places.address'),
      render: ({ adrese }: Place) => <>{adrese?.adrese}</>,
    },
    {
      field: 'faila_id',
      title: t('image'),
      render: ({ faila_id, sha256, nosaukums, content_type }: Place) => (
        <>
          {nosaukums && faila_id && content_type && sha256 && (
            <FileLink file={{ nosaukums, content_type, sha256, id: faila_id }} />
          )}
        </>
      ),
    },
    {
      field: 'komentārs',
      title: t('comment'),
    },
  ]

  const actions = [
    {
      icon: () => <EditIcon fontSize="small" htmlColor={CUSTOM_ORANGE} />,
      onClick: (event: MouseEvent, place: Place) => onUpdatePlace(place),
      tooltip: t('edit'),
      disabled: false,
    },
    {
      icon: () => <DeleteIcon fontSize="small" htmlColor={CUSTOM_RED} />,
      onClick: (event: MouseEvent, place: Place) => onDeletePlace(place),
      tooltip: t('delete'),
      disabled: false,
    },
  ]

  const onAddPlace = () => {
    setDialogTitle(t('places.new'))
    setSelectedPlaceFiles([])
    setSelectedPlace({
      sadarbības_partneris_id: parseInt(partnerId as string, 10),
      adrese: {
        koord_x: 0,
        zip: '',
        novads_nosaukums: '',
        pagasts_nosaukums: '',
        koord_y: 0,
        pilseta_atvk: '',
        code: 0,
        novads_atvk: '',
        adrese: '',
        pilseta_nosaukums: '',
        pagasts_atvk: '',
        vzd_adrese: '',
      },
      komentārs: '',
      nosaukums: '',
    })
  }

  const onUpdatePlace = (place: Place) => {
    setDialogTitle(t('places.edit'))
    setSelectedPlace(place)

    if (place.nosaukums && place.faila_id && place.content_type && place.sha256) {
      setSelectedPlaceFiles([
        {
          file: {
            nosaukums: place.nosaukums,
            content_type: place.content_type,
            sha256: place.sha256,
            id: place.faila_id,
          },
          state: FileState.Keep,
        },
      ])
    } else {
      setSelectedPlaceFiles([])
    }
  }

  const onDeletePlace = (place: Place) => {
    deletePlaceMutation(place)
  }

  const onClose = () => {
    setDialogTitle('')
    setSelectedPlace({} as Place)
  }

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    const newFiles = selectedPlaceFiles.filter((file) => file.state === FileState.New)

    setIsDisabledSubmit(true)

    uploadFiles(newFiles)
      .then(async (uploadedFiles) => {
        const requestData = { ...selectedPlace }

        if (uploadedFiles.length) {
          const json = await uploadedFiles[0].json()

          if (json.id) {
            requestData.nosaukums = json.nosaukums
            requestData.sha256 = json.sha256
            requestData.content_type = json.content_type
            requestData.faila_id = json.id
          }
        } else {
          const removedFiles = selectedPlaceFiles.filter((file) => file.state === FileState.Delete)

          if (removedFiles.length) {
            requestData.nosaukums = undefined
            requestData.sha256 = undefined
            requestData.content_type = undefined
            requestData.faila_id = undefined
          }
        }

        if (selectedPlace.id) {
          updatePlaceMutation(requestData)
        } else {
          createPlaceMutation(requestData)
        }
      })
      .finally(() => setIsDisabledSubmit(false))
  }

  const onPlaceAddressChange = (address: PlaceAddress) => {
    setSelectedPlace({
      ...selectedPlace,
      adrese: address,
    })
  }

  const onPlaceTextFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedPlace({
      ...selectedPlace,
      [event.target.name]: event.target.value,
    })
  }

  const onPlacePhotoChange = (placePhotos: FileListItem[]) => {
    setSelectedPlaceFiles(placePhotos)
  }

  return (
    <Box sx={{ width: '100%' }}>
      <NavBar>
        {isActionAllowed ? (
          <Button
            onClick={onAddPlace}
            variant="outlined"
            disabled={isInitializing || creatingPlace || updatingPlace || deletingPlace}
          >
            {t('add')}
          </Button>
        ) : null}
      </NavBar>
      <Box>
        {isInitializing ? (
          <FullPageLoader />
        ) : data ? (
          <Table
            columns={columns}
            actions={isActionAllowed ? (actions as Action<Place>[]) : []}
            data={data}
            isLoading={isLoading}
          />
        ) : (
          <></>
        )}
      </Box>
      {selectedPlace.sadarbības_partneris_id ? (
        <PlaceDialog
          place={selectedPlace}
          files={selectedPlaceFiles}
          title={dialogTitle}
          loading={isDisabledSubmit || creatingPlace || updatingPlace}
          onClose={onClose}
          onSubmit={onSubmit}
          onPlaceAddressChange={onPlaceAddressChange}
          onTextFieldChange={onPlaceTextFieldChange}
          onPlacePhotoChange={onPlacePhotoChange}
        />
      ) : (
        <></>
      )}
    </Box>
  )
}
