import { Discount } from '../interfaces'
import { DiscountStatusCode } from '../interfaces/discount/DiscountStatusCode'

export const getRowColor = (data: Discount) => {
  switch (data.statuss_kods) {
    case DiscountStatusCode.Aizstāta:
      return '#bababa'
    case DiscountStatusCode.Noraidīta:
      return '#FF0000'
    case DiscountStatusCode.Piedāvāta:
      return '#FF7F50'
    case DiscountStatusCode.Aktīva:
    default:
      return 'inherit'
  }
}
