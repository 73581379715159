import { Dialog, Button, DialogContent, Box } from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'
import { useTranslation } from 'react-i18next'
import { Discount } from '../../interfaces'

const sx = {
  closeIcon: {
    width: 32,
    height: 32,
    cursor: 'pointer',
  },
  button: {
    minWidth: 90,
  },
}

interface ConfirmModalProps {
  onConfirm: () => void
  onClose: () => void
  discountInfo: Discount
}

const DiscountDeleteModal: React.FC<ConfirmModalProps> = ({ onClose, onConfirm, discountInfo }) => {
  const { t } = useTranslation()

  const { skaits, mērvienība_nosaukums, apraksts } = discountInfo

  return (
    <Dialog open>
      <Box pb={2}>
        <Box mt={1} mr={2} display="flex" justifyContent="flex-end">
          <CancelIcon color="primary" sx={sx.closeIcon} onClick={onClose} />
        </Box>
        <DialogContent>
          {t('discounts.remove-discount-modal-content', {
            discount: `${skaits} ${mērvienība_nosaukums} - ${apraksts}`,
          })}
        </DialogContent>
        <Box mt={2} mb={1} display="flex" justifyContent="center">
          <Button sx={sx.button} variant="contained" onClick={onConfirm} color="primary">
            {t('confirm')}
          </Button>
          <Button
            style={{ marginLeft: 20 }}
            sx={sx.button}
            variant="contained"
            onClick={onClose}
            color="primary"
          >
            {t('cancel')}
          </Button>
        </Box>
      </Box>
    </Dialog>
  )
}

export default DiscountDeleteModal
