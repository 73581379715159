import { CSSProperties, ReactNode } from 'react'
import { Box, useTheme } from '@mui/material'

interface OutlinedContainerProps {
  label: ReactNode
  children: JSX.Element | JSX.Element[]
  wrapperStyle?: CSSProperties
  innerWrapperStyle?: CSSProperties
}

export const OutlinedContainer = ({
  label,
  children,
  wrapperStyle,
  innerWrapperStyle,
}: OutlinedContainerProps) => {
  const theme = useTheme()

  return (
    <Box
      component="fieldset"
      style={wrapperStyle}
      sx={{
        m: 0,
        pt: 0,
        pr: 2,
        pb: 0,
        pl: 2,
        height: '100%',
        minHeight: '0',
        minWidth: '0',
        overflow: 'hidden',
        borderStyle: 'solid',
        borderWidth: '1px',
        borderRadius: '4px',
      }}
    >
      <Box component="legend" sx={{ color: theme.palette.text.secondary, pr: 1, pl: 1 }}>
        {label}
      </Box>
      <Box
        sx={{
          pt: 2,
          pb: 2,
          '& > div:not(:first-of-type)': {
            mt: 2,
          },
        }}
        style={innerWrapperStyle}
      >
        {children}
      </Box>
    </Box>
  )
}
