import { useState } from 'react'
import { Box, Button, List, ListItem, Typography } from '@mui/material'
import { useQuery } from 'react-query'
import { useTranslation } from 'react-i18next'
import { format, parseISO } from 'date-fns'

import { getPartner } from '../../api'
import {
  FileLink,
  FormRowGeneric,
  FullPageLoader,
  Grid2xN,
  NavBar,
  OutlinedContainer,
} from '../../components'
import { PartnerRegistered } from '../../interfaces'
import { useCurrentUserStore, usePartnerStore, useSnackbarStore } from '../../store'
import { DEFAULT_DATE_FORMAT } from '../../constants'
import { useNavigate } from 'react-router-dom'
import { parseError } from '../../utils'
import { GenerateAgreement } from './GenerateAgreement'
import { PartnerStatusCode } from '../../interfaces/partner/PartnerStatusCode'
import { sanitize as sanitizeHtml } from '../../utils'

export const Home = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const currentPartner = usePartnerStore((state) => state.currentPartner)
  const showSnackbar = useSnackbarStore((state) => state.showSnackbar)
  const { isContactPerson, isApplicant } = useCurrentUserStore()
  const [data, setData] = useState<PartnerRegistered>({} as PartnerRegistered)
  const sanitizedHTMLDescription = sanitizeHtml(data.apraksts || '')

  const { refetch, isFetching } = useQuery<PartnerRegistered>(
    ['current-partner', currentPartner],
    () => getPartner(currentPartner ? currentPartner.id : ''),
    {
      enabled: !!currentPartner,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setData(data)
      },
      onError: (error) => {
        setData({} as PartnerRegistered)
        showSnackbar(parseError(error, t), { severity: 'error' })
      },
    }
  )

  const editPartner = () => {
    if (currentPartner) {
      navigate('/edit/' + currentPartner.id)
    }
  }

  return (
    <Box sx={{ width: '100%' }}>
      <NavBar>
        {isContactPerson || (isApplicant && data.statuss_kods === PartnerStatusCode.Jauns) ? (
          <Button onClick={editPartner} disabled={isFetching} variant="outlined">
            {t('edit')}
          </Button>
        ) : null}
      </NavBar>
      {isFetching ? (
        <FullPageLoader />
      ) : (
        <Grid2xN>
          <OutlinedContainer label={t('partner.basic-info')}>
            <FormRowGeneric
              useSemicolon
              label={t('partner.registration-number')}
              value={data.reģistrācijas_numurs}
            />
            <FormRowGeneric useSemicolon label={t('name')} value={data.nosaukums} />
            <FormRowGeneric
              useSemicolon
              label={t('partner.legal-name')}
              value={data.juridiskais_nosaukums}
            />
            <FormRowGeneric
              useSemicolon
              label={t('partner.legal-form')}
              value={data.veids_nosaukums}
            />
            <FormRowGeneric useSemicolon label={t('email')} value={data.epasts} />
          </OutlinedContainer>
          <OutlinedContainer label={t('partner.details-info')}>
            <FormRowGeneric
              useSemicolon
              label={t('partner.vat-number')}
              value={data.pvn_reģistrācijas_numurs}
            />
            <FormRowGeneric
              useSemicolon
              label={t('partner.legal-address')}
              value={data.juridiskā_adrese?.adrese}
            />
            <FormRowGeneric useSemicolon label={t('partner.bank')} value={data.banka} />
            <FormRowGeneric
              useSemicolon
              label={t('partner.bank-account')}
              value={data.konta_numurs}
            />
            <FormRowGeneric useSemicolon label={t('partner.swift')} value={data.swift} />
          </OutlinedContainer>
          <OutlinedContainer label={t('partner.additional-information')}>
            <FormRowGeneric
              useSemicolon
              sx={{ alignItems: 'baseline !important' }}
              label={t('partner.service-groups')}
            >
              <List dense>
                {data.pakalpojumu_grupa?.map((group, index) => (
                  <ListItem key={index} disableGutters>
                    <Typography>{group.grupa_nosaukums}</Typography>
                  </ListItem>
                ))}
              </List>
            </FormRowGeneric>
            <FormRowGeneric
              useSemicolon
              sx={{ alignItems: 'baseline !important' }}
              label={t('partner.industry')}
            >
              <List dense>
                {data.pakalpojumu_birkas?.map((tag, index) => (
                  <ListItem key={index} disableGutters>
                    <Typography>{tag.birka_nosaukums}</Typography>
                  </ListItem>
                ))}
              </List>
            </FormRowGeneric>
            <FormRowGeneric useSemicolon label={t('partner.home-page')} value={data.mājas_lapa} />
            <FormRowGeneric
              sx={{ alignItems: 'baseline !important', wordBreak: 'break-word' }}
              useSemicolon
              label={t('partner.online-store')}
            >
              {data.interneta_veikals?.map((storeEntry, index) => (
                <List key={index} dense>
                  <ListItem disableGutters>{storeEntry.url}</ListItem>
                  <ListItem disableGutters>{storeEntry.komentārs}</ListItem>
                </List>
              ))}
            </FormRowGeneric>
            <FormRowGeneric useSemicolon label={t('partner.publicity-photo')}>
              {data.foto ? <FileLink file={data.foto} /> : <></>}
            </FormRowGeneric>
            <FormRowGeneric useSemicolon label={t('partner.logo')}>
              {data.logotips ? <FileLink file={data.logotips} /> : <></>}
            </FormRowGeneric>
            <FormRowGeneric useSemicolon label={t('partner.important-info')}>
              {sanitizedHTMLDescription.__html.length > 0 ? (
                <Box className="ProseMirror" sx={{ borderStyle: 'dashed' }}>
                  <Typography component="span" dangerouslySetInnerHTML={sanitizedHTMLDescription} />
                </Box>
              ) : (
                <></>
              )}
            </FormRowGeneric>
          </OutlinedContainer>
          <OutlinedContainer label={t('partner.agreement')}>
            <FormRowGeneric
              useSemicolon
              label={t('partner.status')}
              value={data.statuss_nosaukums}
            />
            <FormRowGeneric useSemicolon label={t('name')} value={data.līgums?.nosaukums} />
            <FormRowGeneric useSemicolon label={t('partner.number')} value={data.līgums?.numurs} />
            <FormRowGeneric useSemicolon label={t('comment')} value={data.līgums?.komentārs} />
            <FormRowGeneric
              useSemicolon
              label={t('partner.creation-date')}
              value={
                data.līgums?.izveidošanas_datums
                  ? format(parseISO(data.līgums.izveidošanas_datums), DEFAULT_DATE_FORMAT)
                  : ''
              }
            />
            <FormRowGeneric
              useSemicolon
              label={t('partner.due-date')}
              value={
                data.līgums?.termiņš
                  ? format(parseISO(data.līgums.termiņš), DEFAULT_DATE_FORMAT)
                  : ''
              }
            />
            <GenerateAgreement partner={data} onRefetch={refetch} />
          </OutlinedContainer>
        </Grid2xN>
      )}
    </Box>
  )
}
