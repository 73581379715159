import { FileState } from '../enums'
import { FileListItem, PartnerRegistered } from '../interfaces'

export const mapAgreementToFileList = (partner: PartnerRegistered): FileListItem[] => {
  if (partner.līgums && partner.līgums.faili) {
    return partner.līgums.faili.map((file) => ({
      file: {
        id: file.faila_id,
        nosaukums: file.nosaukums || '',
        content_type: file.content_type || '',
        sha256: file.sha256 || '',
      },
      state: FileState.Keep,
    }))
  }

  return []
}
