import { AxiosError } from 'axios'
import { TFunction } from 'react-i18next'

export const parseError = (error: unknown, t: TFunction) => {
  if (typeof error === 'object' && error) {
    const axiosError = error as AxiosError

    if (axiosError.response && axiosError.response.data) {
      const errorData = axiosError.response.data
      const errorStatus = axiosError.response.status

      if (errorStatus === 413 || errorStatus === 0) {
        return `${t('error')}: ${t('errors.invalid-file-size')}`
      } else {
        if (Array.isArray(errorData) && errorData.length) {
          if (errorData[0].kļūdas && errorData[0].kļūdas.length) {
            return `${t('error')}: ${errorData[0].kļūdas[0]}`
          }
        }

        if (typeof errorData === 'string') {
          return `${t('error')}: ${errorData}`
        }
      }
    }

    if (axiosError.message) {
      return `${t('error')}: ${axiosError.message}`
    }
  }

  return t('error')
}
