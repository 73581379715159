import { useEffect, useState } from 'react'
import { Autocomplete, TextField, Box, Grid, Typography } from '@mui/material'
import { AddressResponse, PlaceAddress } from '../../interfaces'
import { fetchAddress } from '../../api'
import { RequiredMarker } from '../RequiredMarker'
import { useTranslation } from 'react-i18next'
import { lks92ToWgs84, stringifyGeom } from '../../utils'

interface AddressAutocompleteRowProps {
  value?: PlaceAddress
  label: string
  required?: boolean
  disabled?: boolean
  onChange: (address: PlaceAddress) => void
}

export const AddressAutocompleteRow = ({
  value,
  label,
  required,
  disabled,
  onChange,
}: AddressAutocompleteRowProps) => {
  const { t } = useTranslation()

  const [inputValue, setInputValue] = useState('')
  const [options, setOptions] = useState<AddressResponse[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [parsedValue, setParsedValue] = useState<AddressResponse | null>(null)

  useEffect(() => {
    const addressQueryParams = {
      limit: '100',
      search: inputValue,
      lks_koord: 'true',
      atvk: 'true',
      struct: 'true',
    }

    async function fetchData() {
      setIsLoading(true)

      fetchAddress(addressQueryParams)
        .then((resp) => setOptions(resp))
        .catch((e) => setOptions([]))
        .finally(() => setIsLoading(false))
    }

    const timeoutId = setTimeout(() => {
      fetchData()
    }, 500)

    return () => {
      clearTimeout(timeoutId)
    }
  }, [inputValue])

  useEffect(() => {
    setParsedValue({
      address: value ? value.adrese : '',
    })
  }, [value])

  const onAddressSelect = (address: AddressResponse | null) => {
    onChange({
      koord_x: 0,
      zip: address?.zipCode || '',
      novads_nosaukums: address?.novName || '',
      pagasts_nosaukums: address?.pagName || '',
      koord_y: 0,
      pilseta_atvk: address?.pilAtvk || '',
      code: address?.code || 0,
      novads_atvk: address?.novAtvk || '',
      adrese: address?.address || '',
      pilseta_nosaukums: address?.pilName || '',
      pagasts_atvk: address?.pagAtvk || '',
      vzd_adrese: '',
      geom: stringifyGeom(lks92ToWgs84(address?.lksLong || 0, address?.lksLat || 0)),
    })
  }

  return (
    <Grid container sx={{ alignItems: 'center' }}>
      <Grid item xs={6}>
        <Typography variant="body1" sx={{ pr: 2, pb: '3px' }}>
          {label}
          {required && <RequiredMarker disabled={disabled} />}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Autocomplete
          disabled={disabled}
          value={parsedValue}
          onChange={(e, address) => onAddressSelect(address)}
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue)
          }}
          loading={isLoading}
          getOptionLabel={(option) => option.address}
          options={options}
          isOptionEqualToValue={(option, value) => option.code === value.code}
          filterOptions={(x) => x}
          sx={{ flexGrow: 1 }}
          disablePortal={true}
          noOptionsText={t('address-not-found')}
          renderOption={(props, jsonResults) => (
            <Box component="li" {...props} key={jsonResults.code}>
              {jsonResults.address}
            </Box>
          )}
          renderInput={(params) => (
            <TextField multiline {...params} sx={{ flexGrow: 1 }} size="small" />
          )}
        />
      </Grid>
    </Grid>
  )
}
