import { FormControl, SelectChangeEvent, Select, MenuItem } from '@mui/material'
import { MUI_SELECT_OVERRIDE } from '../../constants'
import { StorageKey } from '../../enums'
import { usePartnerStore } from '../../store'

export const PartnerSelect = () => {
  const { partnerList, currentPartner } = usePartnerStore()

  const onChange = (event: SelectChangeEvent) => {
    localStorage.setItem(StorageKey.SelectedPartnerId, event.target.value)
    window.location.replace('/')
  }

  if (!partnerList || partnerList.length === 0) {
    return <></>
  }

  return (
    <FormControl sx={MUI_SELECT_OVERRIDE} size="small">
      <Select
        labelId="activated-partner"
        value={currentPartner?.id + '_' + currentPartner?.loma_kods}
        disabled={partnerList.length === 1}
        onChange={onChange}
      >
        {partnerList.map((partner) => (
          <MenuItem key={partner.id} value={partner.id + '_' + partner.loma_kods}>
            {partner.nosaukums}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
