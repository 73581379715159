import {
  Dialog,
  DialogActions,
  DialogTitle,
  Button,
  DialogContent,
  Box,
  Typography,
} from '@mui/material'
import { ChangeEvent, FormEvent } from 'react'
import { useTranslation } from 'react-i18next'
import {
  AddressAutocompleteRow,
  FileControl,
  FormRowGeneric,
  FormTextRow,
} from '../../../components'
import { ACCEPTED_IMAGE_FILE_TYPES } from '../../../constants'
import { FileListItem, PlaceAddress, Place } from '../../../interfaces'

interface PlaceDialogProps {
  place: Place
  files: FileListItem[]
  title: string
  loading: boolean
  onClose: () => void
  onSubmit: (event: FormEvent<HTMLFormElement>) => void
  onPlaceAddressChange: (address: PlaceAddress) => void
  onTextFieldChange: (event: ChangeEvent<HTMLInputElement>) => void
  onPlacePhotoChange: (placePhoto: FileListItem[]) => void
}

export const PlaceDialog = ({
  place,
  files,
  title,
  loading,
  onClose,
  onSubmit,
  onPlaceAddressChange,
  onTextFieldChange,
  onPlacePhotoChange,
}: PlaceDialogProps) => {
  const { t } = useTranslation()

  return (
    <Dialog open={true} fullWidth maxWidth="sm">
      <DialogTitle>{t(title)}</DialogTitle>
      <Box onSubmit={onSubmit} component="form">
        <DialogContent sx={{ '& > div:not(:first-of-type)': { mt: 2 } }}>
          <AddressAutocompleteRow
            value={place.adrese}
            label={t('places.address')}
            onChange={onPlaceAddressChange}
            required
          />
          <FormTextRow
            label={t('comment')}
            value={place.komentārs}
            name="komentārs"
            multiline={true}
            rows={4}
            onChange={onTextFieldChange}
          />
          <FormRowGeneric label={t('image')}>
            <FileControl
              files={files}
              multiple={false}
              useLink={true}
              acceptedUploadFileTypes={ACCEPTED_IMAGE_FILE_TYPES}
              onFileChange={onPlacePhotoChange}
            />
          </FormRowGeneric>
          <FormRowGeneric label={t('')} sx={{ mt: '0px !important' }}>
            <Typography sx={{ ml: 0.5, color: '#999' }}>{t('file-uplaod-note')}</Typography>
          </FormRowGeneric>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} variant="outlined" disabled={loading}>
            {t('cancel')}
          </Button>
          <Button variant="contained" type="submit" disabled={loading || !place.adrese.code}>
            {t('save')}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  )
}
