import Backdrop from '@mui/material/Backdrop'
import { DelayedLoader } from '../DelayedLoader'

export const FullScreenLoader = () => {
  return (
    <Backdrop open={true}>
      <DelayedLoader />
    </Backdrop>
  )
}
