import { GeoCoordinates } from '../interfaces'

export function parseStringToGeom(geomAsString: string = ''): GeoCoordinates | null {
  try {
    const parsedGeo: GeoCoordinates = JSON.parse(geomAsString)
    if (parsedGeo?.lat && parsedGeo?.long) return parsedGeo
    return null
  } catch {
    console.error(`parseStringToGeom - Cannot parse: ${geomAsString}`)
    return null
  }
}

export function stringifyGeom(
  coordinates: null | GeoCoordinates = { lat: 0, long: 0 }
): string | null {
  try {
    const reverseParsedGeom = JSON.stringify({
      type: 'Point',
      coordinates: [coordinates?.long, coordinates?.lat],
    })
    return reverseParsedGeom
  } catch {
    console.error(`convertGeomToString - Cannot parse: ${JSON.stringify(coordinates)}`)
    return null
  }
}
