import { Box, ListItem } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { updatePartner, uploadFiles } from '../../../api'
import { FileControl, FileLink, FullScreenLoader } from '../../../components'
import { ACCEPTED_GENERIC_FILE_TYPES } from '../../../constants'
import { FileState } from '../../../enums'
import {
  FileEntry,
  FileListItem,
  PartnerAgreement,
  PartnerAgreementFile,
  PartnerRegistered,
} from '../../../interfaces'
import { PartnerStatusCode } from '../../../interfaces/partner/PartnerStatusCode'
import { useSnackbarStore } from '../../../store'
import { mapAgreementToFileList, parseError } from '../../../utils'

const sx = {
  fileItem: {
    mr: 2,
    width: '320px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}
interface Props {
  partner: PartnerRegistered
  onRefetch: () => void
  isFileUploadVisible: boolean
}

export const AgreementUpload = ({ partner, onRefetch, isFileUploadVisible }: Props) => {
  const { t } = useTranslation()
  const showSnackbar = useSnackbarStore((state) => state.showSnackbar)
  const isNewPartner = partner.statuss_kods === PartnerStatusCode.Jauns

  const [isLoading, setIsLoading] = useState(false)
  const [agreementFiles, setAgreementFiles] = useState<FileListItem[]>(
    mapAgreementToFileList(partner)
  )

  useEffect(() => {
    setAgreementFiles(mapAgreementToFileList(partner))
  }, [partner])

  const errorHandler = (error: unknown) => {
    setIsLoading(false)
    setAgreementFiles([])
    showSnackbar(parseError(error, t), { severity: 'error' })
  }

  const { mutate: updateMutation } = useMutation(
    (partnerData: PartnerRegistered) => updatePartner(partnerData),
    {
      onSuccess: () => {
        showSnackbar(t('partner.updated'))
        setIsLoading(false)
        onRefetch()
      },
      onError: errorHandler,
    }
  )

  const onFileChange = (files: FileListItem[]) => {
    setIsLoading(true)
    setAgreementFiles(files)

    const keepFiles: PartnerAgreementFile[] = files
      .filter((fileEntry) => fileEntry.state === FileState.Keep)
      .map((file) => ({ faila_id: (file.file as FileEntry).id }))

    uploadFiles(files)
      .then(async (response: Response[]) => {
        const faili: PartnerAgreementFile[] = []

        for (const resp of response) {
          const json = await resp.json()

          faili.push({
            faila_id: json.id,
          })
        }

        updateMutation({
          ...partner,
          līgums: {
            ...(partner.līgums || ({} as PartnerAgreement)),
            faili: [...faili, ...keepFiles],
          },
        })
      })
      .catch(errorHandler)
  }

  return (
    <>
      {isFileUploadVisible ? (
        <FileControl
          onFileChange={onFileChange}
          useLink={true}
          files={agreementFiles}
          acceptedUploadFileTypes={ACCEPTED_GENERIC_FILE_TYPES}
          isDeletable={isNewPartner}
        />
      ) : (
        <>
          {agreementFiles.map((entry, index) => {
            return (
              <Box key={index}>
                {entry.state === FileState.Delete ? (
                  <></>
                ) : (
                  <ListItem key={index} disableGutters>
                    <Box component="span" sx={sx.fileItem}>
                      {entry.file instanceof File ? (
                        entry.file.name
                      ) : (
                        <FileLink file={entry.file} />
                      )}
                    </Box>
                  </ListItem>
                )}
              </Box>
            )
          })}
        </>
      )}

      {isLoading && <FullScreenLoader />}
    </>
  )
}
