import { useState } from 'react'
import { useQuery } from 'react-query'
import { Outlet, useLocation } from 'react-router-dom'
import { getCurrentUser, getPartner, getPartnerList } from '../../api'
import { FullPageLoader, NoPartnerBanner } from '../../components'
import { StorageKey } from '../../enums'
import { CurrentUser, PartnerListItem, PartnerRegistered } from '../../interfaces'
import { useCurrentUserStore, usePartnerStore } from '../../store'

export const Init = () => {
  const location = useLocation()
  const { currentUser, setCurrentUser, setUserRole } = useCurrentUserStore()
  const {
    partnerList,
    currentPartner,
    setPartnerList,
    setCurrentPartner,
    setCurrentPartnerStatus,
  } = usePartnerStore()

  const [hasNoPartners, setHasNoPartners] = useState<boolean>()

  const updateSelectedPartner = (partner: PartnerListItem | undefined) => {
    if (partner) {
      localStorage.setItem(StorageKey.SelectedPartnerId, partner.id + '_' + partner.loma_kods)
    } else {
      localStorage.removeItem(StorageKey.SelectedPartnerId)
    }

    setUserRole(partner?.loma_kods)
    setCurrentPartner(partner)
  }

  useQuery<CurrentUser>('current-user', getCurrentUser, {
    refetchOnWindowFocus: false,
    onSuccess: (user: CurrentUser) => {
      setCurrentUser(user)
    },
  })

  useQuery<PartnerListItem[]>('partner-ist', getPartnerList, {
    refetchOnWindowFocus: false,
    onSuccess: (partnerList: PartnerListItem[]) => {
      const list = partnerList
        ? partnerList.map((partner) => ({ ...partner, id: partner.id + '' }))
        : []

      setHasNoPartners(list.length === 0)

      setPartnerList(list)

      // user does not represent any partner
      if (list.length === 0) {
        updateSelectedPartner(undefined)
        return
      }

      // user represents single partner
      if (list.length === 1) {
        updateSelectedPartner(list[0])
        return
      }

      const storedPartnerString = localStorage.getItem(StorageKey.SelectedPartnerId) || ''
      const [storedPartnerId, storedPartnerRole] = storedPartnerString.split('_')

      // user represents multiple partners, previously selected partner is not stored
      if (!storedPartnerId) {
        updateSelectedPartner(list[0])
        return
      }

      const partnerFromList = list.find(
        (partner) => partner.id === storedPartnerId && partner.loma_kods === storedPartnerRole
      )

      // user represents multiple partners, previously selected partner found in the list
      if (partnerFromList) {
        updateSelectedPartner(partnerFromList)
        return
      }

      // user represents multiple partners, previously selected partner not found in the list
      updateSelectedPartner(list[0])
    },
  })

  useQuery<PartnerRegistered>(
    ['current-partner', currentPartner],
    () => getPartner(currentPartner ? currentPartner.id : ''),
    {
      enabled: !!currentPartner,
      refetchOnWindowFocus: false,
      retry: false,
      onSuccess: (data) => {
        setCurrentPartnerStatus(data.statuss_kods)
      },
    }
  )

  return (
    <>
      {currentUser && partnerList ? (
        <>
          {hasNoPartners === true && location.pathname !== '/register' ? (
            <NoPartnerBanner />
          ) : (
            <Outlet />
          )}
        </>
      ) : (
        <FullPageLoader />
      )}
    </>
  )
}
