import { Button, Box, SxProps } from '@mui/material'
import { useTranslation } from 'react-i18next'
import logo from './latvija-lv-logo.png'

interface VpsButtonProps {
  sx?: SxProps
}

export const VpsButton = ({ sx }: VpsButtonProps) => {
  const { t } = useTranslation()

  const vpsLogin = () => {
    window.location.replace(`${window.location.origin}/api/v1/login_start`)
  }

  return (
    <Button onClick={vpsLogin} variant="outlined" color="primary" sx={sx}>
      <Box
        component="img"
        sx={{
          width: '6rem',
        }}
        src={logo}
        alt={t('auth-lv')}
      />
    </Button>
  )
}
