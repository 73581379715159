import { Dialog, DialogContent, Box, DialogTitle, Typography } from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'
import { useTranslation } from 'react-i18next'

import { Discount } from '../../interfaces'

const sx = {
  closeIcon: {
    width: 32,
    height: 32,
    cursor: 'pointer',
  },
  dialogTitle: {
    paddingTop: 0,
  },
}

interface Props {
  onClose: () => void
  discountInfo: Discount
}

export const DiscountViewModal = ({ onClose, discountInfo }: Props) => {
  const { t } = useTranslation()

  return (
    <Dialog open>
      <Box pb={2}>
        <Box mt={1} mr={2} display="flex" justifyContent="flex-end">
          <CancelIcon color="primary" sx={sx.closeIcon} onClick={onClose} />
        </Box>
        <DialogTitle sx={sx.dialogTitle}>
          <Box display="flex" justifyContent="center" px={12}>
            {t('discounts.data')}
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box display="flex" flexDirection="column">
            <Typography>
              <b>{t('discounts.quantity')}: </b>
              {discountInfo?.skaits}
            </Typography>
            <Typography>
              <b>{t('discounts.unit')}: </b>
              {discountInfo?.mērvienība_nosaukums}
            </Typography>
            <Typography>
              <b>{t('discounts.description')}: </b>
              {discountInfo?.apraksts}
            </Typography>
            <Typography>
              <b>{t('discounts.status')}: </b>
              {discountInfo?.statuss_nosaukums}
            </Typography>
          </Box>
        </DialogContent>
      </Box>
    </Dialog>
  )
}
