import React, { FormEvent, useState } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  SelectChangeEvent,
} from '@mui/material'
import { PartnerAgreementSignatory } from '../../../interfaces'
import { REQUIRED_ERROR_MESSAGE } from '../../../constants'
import { FormSelectRow, FormTextRow } from '../../../components'
import { generateAgreement } from '../../../api'
import { parseError } from '../../../utils'
import { usePartnerStore, useSnackbarStore } from '../../../store'
import { useMutation } from 'react-query'
import { useTranslation } from 'react-i18next'

interface GenerateAgreementDialogProps {
  onClose: () => void
}

export const GenerateAgreementDialog = ({ onClose }: GenerateAgreementDialogProps) => {
  const { t } = useTranslation()
  const showSnackbar = useSnackbarStore((state) => state.showSnackbar)
  const currentPartner = usePartnerStore((state) => state.currentPartner)

  const [formErrors, setFormErrors] = useState<{ [key: string]: string }>({})
  const [signatory, setSignatory] = useState({
    ir_eparaksts: '',
    amats: '',
    parakstitajs: '',
  })

  const templateSelect = [
    {
      name: t('partner.paper-agreement'),
      code: 'papīra',
    },
    {
      name: t('partner.electronic-agreement'),
      code: 'elektroniska',
    },
  ]

  const { mutate: generateAgreementMutation, isLoading } = useMutation(
    (signatory: PartnerAgreementSignatory) => generateAgreement(signatory, currentPartner!.id),
    {
      onSuccess: async (response) => {
        showSnackbar(t('partner.agreement-generated'))
        openAgreement(response.data)
        onClose()
      },
      onError: (error: unknown) => {
        showSnackbar(parseError(error, t), { severity: 'error' })
      },
    }
  )

  const onTextFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSignatory({
      ...signatory,
      [event.target.name]: event.target.value,
    })

    setFormErrors({
      ...formErrors,
      [event.target.name]: '',
    })
  }

  const onSelectChange = (event: SelectChangeEvent) => {
    setSignatory({
      ...signatory,
      [event.target.name]: event.target.value,
    })

    setFormErrors({
      ...formErrors,
      [event.target.name]: '',
    })
  }

  const validateForm = () => {
    const errors: { [key: string]: string } = {}

    if (!signatory.ir_eparaksts) {
      errors['ir_eparaksts'] = REQUIRED_ERROR_MESSAGE
    }

    if (!signatory.amats) {
      errors['amats'] = REQUIRED_ERROR_MESSAGE
    }

    if (!signatory.parakstitajs) {
      errors['parakstitajs'] = REQUIRED_ERROR_MESSAGE
    }

    setFormErrors({
      ...formErrors,
      ...errors,
    })

    return Object.keys(errors).length
  }

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    const isInvalid = validateForm()

    if (isInvalid) {
      return
    }

    generateAgreementMutation({
      ir_eparaksts: signatory.ir_eparaksts === 'elektroniska',
      amats: signatory.amats,
      parakstitajs: signatory.parakstitajs,
    })
  }

  const openAgreement = (blob: Blob) => {
    window.open(window.URL.createObjectURL(blob))
  }

  return (
    <Dialog open={true} fullWidth>
      <DialogTitle>Ģenerēt parakstāmo līgumu</DialogTitle>
      <Box onSubmit={onSubmit} component="form">
        <DialogContent sx={{ '& > div:not(:first-of-type)': { mt: 2 } }}>
          <FormSelectRow
            label={t('partner.agreement-version')}
            value={signatory.ir_eparaksts}
            name="ir_eparaksts"
            selectOptions={templateSelect}
            onChange={onSelectChange}
            required={true}
            error={formErrors['ir_eparaksts']}
          />
          <FormTextRow
            label={t('partner.signatory-position')}
            value={signatory.amats}
            name="amats"
            onChange={onTextFieldChange}
            required={true}
            error={formErrors['amats']}
          />
          <FormTextRow
            label={t('partner.signatory-name')}
            value={signatory.parakstitajs}
            name="parakstitajs"
            onChange={onTextFieldChange}
            required={true}
            error={formErrors['parakstitajs']}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} variant="outlined" disabled={isLoading}>
            {t('cancel')}
          </Button>
          <Button variant="contained" type="submit" disabled={isLoading}>
            {t('generate')}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  )
}
