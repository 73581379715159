import {
  Dialog,
  DialogActions,
  DialogTitle,
  Button,
  DialogContent,
  SelectChangeEvent,
  Box,
} from '@mui/material'
import { ChangeEvent, FormEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { FormSelectRow, FormTextRow } from '../../../components'
import { PHONE_PLACEHOLDER } from '../../../constants/config'
import { SelectItem, User } from '../../../interfaces'

interface UserDialogProps {
  user: User
  title: string
  contactPersonRoles: SelectItem[]
  errors: { [key: string]: string }
  loading: boolean
  onTextFieldChange: (event: ChangeEvent<HTMLInputElement>) => void
  onSelectChange: (event: SelectChangeEvent<string>) => void
  onClose: () => void
  onSubmit: (event: FormEvent<HTMLFormElement>) => void
}

export const UserDialog = ({
  user,
  title,
  contactPersonRoles,
  errors,
  loading,
  onTextFieldChange,
  onSelectChange,
  onClose,
  onSubmit,
}: UserDialogProps) => {
  const { t } = useTranslation()

  return (
    <Dialog open={true} fullWidth>
      <DialogTitle>{t(title)}</DialogTitle>
      <Box onSubmit={onSubmit} component="form">
        <DialogContent sx={{ '& > div:not(:first-of-type)': { mt: 2 } }}>
          <FormTextRow
            label={t('users.name')}
            value={user.vārds}
            name="vārds"
            onChange={onTextFieldChange}
          />
          <FormTextRow
            label={t('users.surname')}
            value={user.uzvārds}
            name="uzvārds"
            onChange={onTextFieldChange}
          />
          <FormTextRow
            label={t('users.personal-number')}
            value={user.personas_kods}
            name="personas_kods"
            onChange={onTextFieldChange}
            required={true}
            error={errors['personas_kods']}
          />
          <FormTextRow
            label={t('users.phone')}
            value={user.telefons}
            name="telefons"
            placeholder={PHONE_PLACEHOLDER}
            onChange={onTextFieldChange}
            required={true}
            error={errors['tālrunis']}
          />
          <FormTextRow
            label={t('email')}
            value={user.epasts}
            name="epasts"
            onChange={onTextFieldChange}
            required={true}
            error={errors['epasts']}
          />
          <FormSelectRow
            label={t('users.role')}
            value={user.loma_kods}
            name="loma_kods"
            selectOptions={contactPersonRoles}
            onChange={onSelectChange}
            required={true}
            error={errors['loma_kods']}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} variant="outlined" disabled={loading}>
            {t('cancel')}
          </Button>
          <Button variant="contained" type="submit" disabled={loading}>
            {t('save')}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  )
}
