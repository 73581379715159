import { useState } from 'react'
import { SelectChangeEvent, SxProps, Box, CircularProgress, Button } from '@mui/material'
import { ChangeEvent, CSSProperties } from 'react'
import { useTranslation } from 'react-i18next'
import { OutlinedContainer, FormTextRow, FormSelectRow } from '..'
import { PartnerNew, SelectItem } from '../../interfaces'
import { REGEX_ONLY_NUMBERS } from '../../constants'

interface PartnerEditBasicInformationProps {
  partnerData: PartnerNew
  partnerTypeClassifier: SelectItem[]
  errors: { [key: string]: string }
  onTextFieldChange: (event: ChangeEvent<HTMLInputElement>) => void
  onSelectChange: (event: SelectChangeEvent<string>) => void
  handleRegistryData: () => void
  isRegistryDataLoading: boolean
}

const sx: { [key: string]: CSSProperties | SxProps } = {
  registryFieldBox: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  button: {
    marginTop: '16px',
    width: '45%',
  },
}

export const PartnerEditBasicInformation = ({
  partnerData,
  partnerTypeClassifier,
  errors,
  onTextFieldChange,
  onSelectChange,
  handleRegistryData,
  isRegistryDataLoading,
}: PartnerEditBasicInformationProps) => {
  const { t } = useTranslation()
  const [invalidRegistryNumber, setInvalidRegistryNumber] = useState<string>('')

  const handleFetchFromRegistry = () => {
    if (!partnerData.reģistrācijas_numurs.match(REGEX_ONLY_NUMBERS)) {
      setInvalidRegistryNumber('Reģistrācijas numuru norādīt tikai ar cipariem')
    } else {
      handleRegistryData()
      setInvalidRegistryNumber('')
    }
  }

  return (
    <OutlinedContainer label={t('partner.basic-info')}>
      <Box sx={sx.registryFieldBox}>
        <FormTextRow
          label={t('partner.registration-number')}
          value={partnerData.reģistrācijas_numurs}
          name="reģistrācijas_numurs"
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setInvalidRegistryNumber('')
            onTextFieldChange(e)
          }}
          required={true}
          error={errors['reģistrācijas_numurs'] || invalidRegistryNumber}
        />
        <Button
          sx={sx.button}
          disabled={!partnerData.reģistrācijas_numurs || Boolean(errors['reģistrācijas_numurs'])}
          color="primary"
          variant="contained"
          onClick={handleFetchFromRegistry}
        >
          {isRegistryDataLoading ? (
            <CircularProgress color="inherit" size={24} />
          ) : (
            t('partner.data-from-registry')
          )}
        </Button>
      </Box>
      <FormTextRow
        label={t('name')}
        value={partnerData.nosaukums}
        name="nosaukums"
        onChange={onTextFieldChange}
        required={true}
        error={errors['nosaukums']}
      />
      <FormTextRow
        label={t('partner.legal-name')}
        value={partnerData.juridiskais_nosaukums}
        name="juridiskais_nosaukums"
        onChange={onTextFieldChange}
      />
      <FormSelectRow
        label={t('partner.legal-form')}
        value={partnerData.veids_kods}
        name="veids_kods"
        selectOptions={partnerTypeClassifier}
        onChange={onSelectChange}
        required={true}
        error={errors['veids_kods']}
      />
      <FormTextRow
        label={t('email')}
        value={partnerData.epasts}
        name="epasts"
        onChange={onTextFieldChange}
      />
    </OutlinedContainer>
  )
}
