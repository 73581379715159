import { Box } from '@mui/material'

interface Grid2xNProps {
  children: JSX.Element | JSX.Element[]
}

export const Grid2xN = ({ children }: Grid2xNProps) => {
  return (
    <Box
      sx={{
        minHeight: '0',
        minWidth: '0',
        display: 'grid',
        gridTemplateColumns: '49% 49%',
        columnGap: '2%',
        rowGap: '16px',
        width: '100%',
      }}
    >
      {children}
    </Box>
  )
}
