import axios from 'axios'

import { ReplyInfoRequest } from '../../interfaces'

export const replyInfoRequest = async (infoRequest: ReplyInfoRequest) => {
  const response = await axios.put(
    '/data/sadarbības_partnera_informācijas_pieprasījums/' + infoRequest.id,
    infoRequest
  )

  return response.data
}
