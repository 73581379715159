import React from 'react'
import { Button, Divider, Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import { useCurrentUserStore, usePartnerStore } from '../../store'

export const NavBar: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const { t } = useTranslation()
  const currentPartner = usePartnerStore((state) => state.currentPartner)
  const { isRegistryWorker, isPublicRelationsSpecialist, isApplicant } = useCurrentUserStore()

  const navMenu = [
    {
      to: '/',
      label: t('nav.home'),
      isVisible: !isRegistryWorker,
    },
    {
      to: '/users/' + currentPartner?.id,
      label: t('nav.users'),
      isVisible: !isRegistryWorker && !isPublicRelationsSpecialist,
    },
    {
      to: '/discounts/' + currentPartner?.id,
      label: t('nav.discounts'),
      isVisible: !isRegistryWorker,
    },
    {
      to: '/places/' + currentPartner?.id,
      label: t('nav.places'),
      isVisible: !isRegistryWorker && !isPublicRelationsSpecialist,
    },
    {
      to: '/promotions/' + currentPartner?.id,
      label: t('nav.promotions'),
      isVisible: !isRegistryWorker && !isApplicant,
    },
    {
      to: '/complaints/' + currentPartner?.id,
      label: t('nav.complaints'),
      isVisible: !isRegistryWorker,
    },
    {
      to: '/info-requests/' + currentPartner?.id,
      label: t('nav.info-requests'),
      isVisible: !isRegistryWorker,
    },
    {
      to: '/certificate-verificaiton',
      label: t('nav.certificate-verification'),
      isVisible: !isPublicRelationsSpecialist && !isApplicant,
    },
  ]

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ mb: 2, display: 'flex' }}>
        <Box>
          {currentPartner &&
            navMenu.map((menuItem, index) =>
              menuItem.isVisible ? (
                <NavLink
                  to={menuItem.to}
                  key={index}
                  style={({ isActive }) =>
                    isActive ? { textDecoration: 'underline' } : { textDecoration: 'none' }
                  }
                >
                  <Button sx={{ textDecoration: 'inherit' }}>{menuItem.label}</Button>
                </NavLink>
              ) : null
            )}
        </Box>
        <Box sx={{ ml: 'auto' }}>{children}</Box>
      </Box>
      <Divider sx={{ mb: 4 }} />
    </Box>
  )
}
